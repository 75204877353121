import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-profile-delete-modal',
  templateUrl: './profile-delete-modal.component.html',
  styleUrls: ['./profile-delete-modal.component.scss']
})
export class ProfileDeleteModalComponent implements OnInit {

  @Input() name;

  faQuestionCircle = faQuestionCircle;

  constructor(public modal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
