import { Component, OnInit } from '@angular/core';

import { AppStateService } from './../app-state.service';

import { faEdit, faChevronLeft, faPlus, faCheck, faTrash, faSync } from '@fortawesome/pro-regular-svg-icons';

import { CurrentTagService } from './current-tag.service';

import { Pulsar4Service } from '../pulsar-4.service';

import { FormBuilder, FormGroup, FormArray } from '@angular/forms';

import { ColorEvent } from 'ngx-color';

import { Router } from '@angular/router';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent implements OnInit {

  faEdit = faEdit;
  faChevronLeft = faChevronLeft;
  faCheck = faCheck;
  faTrash = faTrash; 
  faPlus = faPlus;
  faSync = faSync;
  
  Hex = "#000000";
  
  public isLoading = true;  
  public isUpdating = false;
  
  public tagID;

  constructor(private appStateService: AppStateService, private currentTagService: CurrentTagService, private pulsarService: Pulsar4Service, private fb: FormBuilder, private router: Router) {
  }

  myForm: FormGroup;

  ngOnInit() {

    this.tagID = this.currentTagService.id;

    if (this.tagID != 'new') this.GrabTag(this.tagID);
    else this.isLoading = false;

    setTimeout(() => {
  	this.appStateService.hideSidebar();
      }, 
    0);

	this.myForm = this.fb.group({
	  Name: '',
	  Hex: this.Hex
	});

  }

  onSubmit(form: FormGroup) {

    this.isUpdating = true;

    let formData = {
	  Name: form.value.Name,
	  Hex: form.value.Hex
	};

    if (this.tagID != 'new') {
	  this.pulsarService.putTag(this.tagID, formData).subscribe(Tag => {
		  this.router.navigate(['/tag']);
	  });    	  
	}
    else {
	  this.pulsarService.postTag(formData).subscribe(Tag => {
		  this.router.navigate(['/tag']);
	  });    	  
	}

  }

  handleChangeComplete($event: ColorEvent) {
    this.myForm.patchValue({'Hex':$event.color.hex});
  }

  public GrabTag(tagID) {

	  this.isLoading = true;
	  this.pulsarService.getTag(tagID).subscribe(Tag => {

	  	  let formData = {
		  	Name: Tag.Tag.Name,
		  	Hex: Tag.Tag.Hex
		  };
		  
		  this.myForm.patchValue(formData);

		  this.Hex = Tag.Tag.Hex;
		  this.isLoading = false;
	  });    	  

  }

}