import { Component, OnInit } from '@angular/core';

import { AppStateService } from './../app-state.service';

import { faEdit, faChevronLeft, faPlus, faCheck, faTrash, faSync } from '@fortawesome/pro-regular-svg-icons';

import { CurrentFirewallService } from './current-firewall.service';

import { Pulsar4Service } from '../pulsar-4.service';

import { FormBuilder, FormGroup, FormArray } from '@angular/forms';

import { Router } from '@angular/router';

@Component({
  selector: 'app-firewall',
  templateUrl: './firewall.component.html',
  styleUrls: ['./firewall.component.scss']
})
export class FirewallComponent implements OnInit {

  faEdit = faEdit;
  faChevronLeft = faChevronLeft;
  faCheck = faCheck;
  faTrash = faTrash; 
  faPlus = faPlus;
  faSync = faSync;

  public isLoading = true;  
  public isUpdating = false;
  
  public profileID;

  constructor(private appStateService: AppStateService, private currentFirewallService: CurrentFirewallService, private pulsarService: Pulsar4Service, private fb: FormBuilder, private router: Router) {
  }

  myForm: FormGroup;

  ngOnInit() {

    this.profileID = this.currentFirewallService.id;

    if (this.profileID != 'new') this.GrabProfile(this.profileID);
    else this.isLoading = false;

    setTimeout(() => {
  	this.appStateService.hideSidebar();
      }, 
    0);
    
	this.myForm = this.fb.group({
	  name: '',
	  description: '',
	  outboundDefault: 'deny',
	  outboundExceptions: this.fb.array([this.generateOutboundExceptionFormGroup({})]),
	  inboundDefault: 'deny',
	  inboundExceptions: this.fb.array([this.generateInboundExceptionFormGroup({})])
	});    
    
  }

  generateInboundExceptionFormGroup(InboundException) {

  	return this.fb.group({
	  InboundProtocol: InboundException.protocol || '',
	  InboundPortRange: InboundException.displayPort || '',
	  InboundIPPrefix: InboundException.displayException || '',
	  InboundDescription: InboundException.label || ''
	});
	
  }

  generateOutboundExceptionFormGroup(OutboundException) {

  	return this.fb.group({
	  OutboundProtocol: OutboundException.protocol || '',
	  OutboundPortRange: OutboundException.displayPort || '',
	  OutboundIPPrefix: OutboundException.displayException || '',
	  OutboundDescription: OutboundException.label || ''
	});
	
  }
  
  createOutboundExceptionFormGroup() {
	  (this.myForm.get('outboundExceptions') as FormArray).push(this.generateOutboundExceptionFormGroup({}));
  }

  deleteOutboundExceptionFormGroup(i) {
	  (this.myForm.get('outboundExceptions') as FormArray).removeAt(i);
  }

  createInboundExceptionFormGroup() {
	  (this.myForm.get('inboundExceptions') as FormArray).push(this.generateInboundExceptionFormGroup({}));
  }

  deleteInboundExceptionFormGroup(i) {
	  (this.myForm.get('inboundExceptions') as FormArray).removeAt(i);
  }

  onSubmit(form: FormGroup) {
 
    this.isUpdating = true;

    let formData = {
	  Name: form.value.name,
	  Description: form.value.description,
	  OutboundDefault: form.value.outboundDefault,
	  OutboundExceptions: form.value.outboundExceptions,
	  InboundDefault: form.value.inboundDefault,
	  InboundExceptions: form.value.inboundExceptions
	};
	
    if (this.profileID != 'new') {
	  this.pulsarService.putFirewallProfile(this.profileID, formData).subscribe(Profile => {
		  if (Profile.Profile.uid) this.router.navigate(['/firewall-profile']);
	  });   	  
	}
    else {
	  this.pulsarService.postFirewallProfile(formData).subscribe(Profile => {
		  if (Profile.Profile.uid) this.router.navigate(['/firewall-profile']);
	  });   	  
	}

  }

  public GrabProfile(profileID) {

	  this.isLoading = true;
	  this.pulsarService.getFirewallProfile(profileID).subscribe(Profile => {

	  	  let formData = {
		  	name: Profile.Profile.label,
		  	description: Profile.Profile.customData.description,
		  	inboundDefault: Profile.Profile.inbound.defaultAction,
		  	outboundDefault: Profile.Profile.outbound.defaultAction
		  };
		  
		  this.myForm.patchValue(formData);

		  let outboundArray = [];
		  
		  Profile.Profile.outbound.exceptions.forEach(Exception => {
		    outboundArray.push(this.generateOutboundExceptionFormGroup(Exception));
		  });

		  this.myForm.setControl('outboundExceptions', this.fb.array(outboundArray || []));

		  let inboundArray = [];
		  
		  Profile.Profile.inbound.exceptions.forEach(Exception => {
		    inboundArray.push(this.generateInboundExceptionFormGroup(Exception));
		  });

		  this.myForm.setControl('inboundExceptions', this.fb.array(inboundArray || []));

		  this.isLoading = false;

	  }); 	  

  }


}
