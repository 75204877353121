import { Component, OnInit, ViewChild } from '@angular/core';

import { faPlus, faEdit, faCopy, faTrash } from '@fortawesome/pro-regular-svg-icons';

import { AppStateService } from './../app-state.service';
import { Pulsar4Service } from '../pulsar-4.service';

import { SmartTable, of, TableState } from 'smart-table-ng';
import { DefaultSettingsService } from './default-settings.service';
import server from 'smart-table-server';

import { trigger, state, style, animate, transition } from '@angular/animations';

const providers = [{
  provide: SmartTable,
  useFactory: (Pulsar: Pulsar4Service, settings: TableState) => of([], settings, server({
    query: (tableState) => Pulsar.getFirewallProfilesTable(tableState)
  })),
  deps: [Pulsar4Service, DefaultSettingsService]
}];

@Component({
  selector: 'app-firewalls',
  templateUrl: './firewalls.component.html',
  styleUrls: ['./firewalls.component.scss'],
  providers,
  animations: [
    trigger('fade', [      
      transition('void => *', [
        style({opacity: 0}),
        animate(200, style({opacity: 1}))
      ]),
      transition('* => void', [
        animate(200, style({opacity: 0}))
      ])
    ])
  ]
})

export class FirewallsComponent implements OnInit {

  faPlus = faPlus;
  faEdit = faEdit;
  faCopy = faCopy; 
  faTrash = faTrash;
  
  Profiles: any = [];
  public isLoading = true; 
  public showCard = false; 

  selectedRow : Number = null;
  ActiveProfile: any = [];
  
  @ViewChild('table', { static: false }) table;

  constructor(private appStateService: AppStateService, private pulsarService: Pulsar4Service) {}

  ngOnInit() {
    setTimeout(() => {
  	this.appStateService.showSidebar();
      }, 
    0);
  }
  
  RowSelected(i, u, e) {
	  if (this.selectedRow == i) {
		  this.selectedRow = null;
		  this.ActiveProfile = [];	
		  this.showCard = false;	  
	  } else {
		  this.selectedRow = i;
		  this.ActiveProfile = u;
		  this.showCard = true;

		  setTimeout(() => {
			  window.scrollTo({
			    top: e.offsetTop - 50, //add your necessary value
			    behavior: "smooth"  //Smooth transition to roll
			  });
		  });

	  }
  }

}
