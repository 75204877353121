import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GoogleChartsModule } from 'angular-google-charts';
import { SmartTableModule } from 'smart-table-ng';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgBytesPipeModule } from 'angular-pipes';
import { TagInputModule } from 'ngx-chips';
import { ReactiveFormsModule } from '@angular/forms';
import { ColorTwitterModule } from 'ngx-color/twitter';
import { OAuthModule } from 'angular-oauth2-oidc';

import { AppComponent } from './app.component';
import { ServiceComponent } from './service/service.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ServicesComponent } from './services/services.component';
import { FirewallsComponent } from './firewalls/firewalls.component';
import { FirewallComponent } from './firewall/firewall.component';
import { AlertComponent } from './alert/alert.component';
import { AlertsComponent } from './alerts/alerts.component';
import { BarsComponent } from './bars/bars.component';
import { BarComponent } from './bar/bar.component';
import { ServicesPreviewComponent } from './services-preview/services-preview.component';
import { TemplatesComponent } from './templates/templates.component';
import { TemplateComponent } from './template/template.component';
import { TagsComponent } from './tags/tags.component';
import { TagComponent } from './tag/tag.component';

import { AuthFunction } from './shared';
import { LogoutComponent } from './logout/logout.component';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import { ProfileDeleteModalComponent } from './profile-delete-modal/profile-delete-modal.component';

import { environment } from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    ServiceComponent,
    DashboardComponent,
    ServicesComponent,
    FirewallsComponent,
    FirewallComponent,
    AlertComponent,
    AlertsComponent,
    BarsComponent,
    BarComponent,
    ServicesPreviewComponent,
    TemplatesComponent,
    TemplateComponent,
    TagsComponent,
    TagComponent,
    LogoutComponent,
    ErrorModalComponent,
    ProfileDeleteModalComponent
  ],
  imports: [
    HttpModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FontAwesomeModule,
    GoogleChartsModule,
    FormsModule,
    SmartTableModule,
    BrowserAnimationsModule,
    NgBytesPipeModule,
    TagInputModule,
    ReactiveFormsModule,
    ColorTwitterModule,
    OAuthModule.forRoot({
    	resourceServer: {
        	allowedUrls: [environment.apiUrl],
			sendAccessToken: true
		}
	})
  ],
  providers: [AuthFunction],
  bootstrap: [AppComponent]
})
export class AppModule { }
