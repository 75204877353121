import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of, EMPTY }  from 'rxjs';

import { CurrentServiceService } from './current-service.service';

@Injectable({
  providedIn: 'root'
})
export class CurrentServiceResolver implements Resolve<any> {
    constructor(private currentServiceService: CurrentServiceService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
        this.currentServiceService.id = route.paramMap.get('id');
        
        // Need to return an observable for all resolve() methods
        return of(null);
    }

}