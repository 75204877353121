import { Component, OnInit } from '@angular/core';

import { AppStateService } from './../app-state.service';

import { faEdit, faChevronLeft, faPlus, faCheck, faTrash, faSync } from '@fortawesome/pro-regular-svg-icons';

import { CurrentBarService } from './current-bar.service';

import { Pulsar4Service } from '../pulsar-4.service';

import { FormBuilder, FormGroup, FormArray } from '@angular/forms';

import { Router } from '@angular/router';

@Component({
  selector: 'app-bar',
  templateUrl: './bar.component.html',
  styleUrls: ['./bar.component.scss'],
  providers: [ ]
})
export class BarComponent implements OnInit {

  faEdit = faEdit;
  faChevronLeft = faChevronLeft;
  faCheck = faCheck;
  faTrash = faTrash; 
  faPlus = faPlus;
  faSync = faSync;
  
  public isLoading = true;  
  public isUpdating = false;
  public profileID;

  constructor(private appStateService: AppStateService, private currentBarService: CurrentBarService, private pulsarService: Pulsar4Service, private fb: FormBuilder, private router: Router) {
  }

  myForm: FormGroup;

  ngOnInit() {

    this.profileID = this.currentBarService.id;

    if (this.profileID != 'new') this.GrabProfile(this.profileID);
    else this.isLoading = false;

    setTimeout(() => {
  	this.appStateService.hideSidebar();
      }, 
    0);

	this.myForm = this.fb.group({
	  name: '',
	  status: 'Enabled',
	  conditions: this.fb.array([this.generateConditionFormGroup({})]),
	  notifications: this.fb.array([this.generateNotificationFormGroup({})])
	});

  }

  onSubmit(form: FormGroup) {

    this.isUpdating = true;

    let formData = {
	  Name: form.value.name,
	  Status: form.value.status,
	  Conditions: form.value.conditions,
	  Notifications: form.value.notifications
	};

    if (this.profileID != 'new') {
	  this.pulsarService.putBarringProfile(this.profileID, formData).subscribe(Profile => {
		  if (Profile.Profile.id) this.router.navigate(['/bar-profile']);
	  });    	  
	}
    else {
	  this.pulsarService.postBarringProfile(formData).subscribe(Profile => {
		  if (Profile.Profile.id) this.router.navigate(['/bar-profile']);
	  });    	  
	}

  }

  generateNotificationFormGroup(Notification) {

  	return this.fb.group({
	  Type: Notification.Type || '',
	  Destination: Notification.Destination || ''
	});
	
  }

  generateConditionFormGroup(Conditions) {

  	return this.fb.group({
	  	Action: Conditions.monitor || '',
	  	Volume: Conditions.volumeConverted || '',
	  	Percentage: Conditions.percentage || ''
  	});
	
  }

  createConditionFormGroup() {
	  (this.myForm.get('conditions') as FormArray).push(this.generateConditionFormGroup({}));
  }

  deleteConditionFormGroup(i) {
	  (this.myForm.get('conditions') as FormArray).removeAt(i);
  }
 
  createNotificationFormGroup() {
	  (this.myForm.get('notifications') as FormArray).push(this.generateNotificationFormGroup({}));
  }

  deleteNotificationFormGroup(i) {
	  (this.myForm.get('notifications') as FormArray).removeAt(i);
  }
  
  public GrabProfile(profileID) {

	  this.isLoading = true;
	  this.pulsarService.getBarringProfile(profileID).subscribe(Profile => {

	  	  let formData = {
		  	name: Profile.Profile.name,
		  	status: Profile.Profile.status
		  };
		  
		  this.myForm.patchValue(formData);

		  let actionArray = [];
		  
		  Profile.Profile.actions.forEach(action => {
		    actionArray.push(this.generateConditionFormGroup(action));
		  });

		  this.myForm.setControl('conditions', this.fb.array(actionArray || []));

		  let notifcationArray = [];
		  
		  Profile.Profile.Notifications.forEach(Notification => {
		    notifcationArray.push(this.generateNotificationFormGroup(Notification));
		  });

		  this.myForm.setControl('notifications', this.fb.array(notifcationArray || []));

		  this.isLoading = false;

	  });    	  

  }

}