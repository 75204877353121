<div style="position: relative" stTable #table="stTable" [ngClass]="{'list-preview-margin': showCard}">

	<div class="d-flex justify-content-between my-3">  

		<form class="flex-grow-1">
			<div class="form-row">
			    <div class="col">
			      <input type="text" class="form-control" placeholder="Filter Firewall Profiles" stSearch="Profile.label">
			    </div>
			</div>
		</form>

		<div class="text-right ml-2">
			<a [routerLink]="['/firewall-profile','new']" class="btn btn-primary"><fa-icon [icon]="faPlus" class="mr-1"></fa-icon> Create Firewall Profile</a>
		</div>

	</div>
	
	<table class="table">
	  <thead>
	    <tr>
	      <th scope="col">Name</th>
	      <th scope="col" colspan="2">Inbound</th>
	      <th scope="col" colspan="2">Outbound</th>
	    </tr>
	  </thead>
	  <tbody>
	    <tr *ngFor="let Profile of table.items; let i = index" (click)="RowSelected(i, Profile, ProfileRow);" [class.table-active]="i === selectedRow" #ProfileRow>
	      <td><strong>{{Profile.label}}</strong></td>
	      <td>{{Profile.inbound.defaultAction | titlecase }} All</td>
	      <td>{{Profile.inbound.exceptions.length}} Rules</td>
	      <td>{{Profile.outbound.defaultAction | titlecase }} All</td>
	      <td>{{Profile.outbound.exceptions.length}} Rules</td>
	    </tr>
	  </tbody>
	</table>

	<div stPagination #pager="stPagination" class="d-flex justify-content-between">
		<div class="d-flex">
			<div *ngIf="table.busy; else pages" class="loading-indicator text-center" style="position: relative;"><div class="loading-spinner loading-spinner-small"></div></div>
			<ng-template #pages>
				<p>Showing items <em>{{pager.length > 0 ? pager.lowerBoundIndex + 1 : 0}}</em> - <em>{{pager.higherBoundIndex +
          1}}</em> of <em>{{pager.length}} </em> items
				</p>
			</ng-template>
		</div>

		<ul class="pagination">
			<li class="page-item" [ngClass]="{'disabled': !pager.isPreviousPageEnabled()}"><button class="page-link" (click)="pager.selectPreviousPage()" >Previous</button></li>
			<li class="page-item" [ngClass]="{'disabled': !pager.isNextPageEnabled()}"><button class="page-link" (click)="pager.selectNextPage()" >Next</button></li>
		</ul>
	</div>

</div>

<div class="card shadow list-preview" @fade *ngIf="showCard">
  <div class="card-body">
	
	<div class="d-flex justify-content-between">  
    	<h5 class="card-title mb-0">{{ActiveProfile.label}}</h5>
    	<div class="text-right">
			<a [routerLink]="['/firewall-profile',ActiveProfile.uid]" class="btn btn-sm btn-secondary mr-2"><fa-icon [icon]="faEdit" class="mr-1"></fa-icon> Modify</a>
			<!--<a [routerLink]="" class="btn btn-sm btn-secondary mr-2"><fa-icon [icon]="faCopy" class="mr-1"></fa-icon> Copy</a>
			<a [routerLink]="" class="btn btn-sm btn-danger mr-2"><fa-icon [icon]="faTrash" class="mr-1"></fa-icon> Delete</a>-->
    	</div>
	</div>

	<ul ngbNav #nav="ngbNav" class="nav-pills mt-2">
	  <li ngbNavItem>
	    <a ngbNavLink>Summary</a>
	    <ng-template ngbNavContent> 
	    
			<div class="mt-3">
				<dl class="row mb-0">
				  <dt class="col-sm-3 text-right">Last Updated</dt>
				  <dd class="col-sm-9">{{ActiveProfile.lastModificationDate | date: 'long'}}</dd>
				  <dt class="col-sm-3 text-right">Status</dt>
				  <dd class="col-sm-9" *ngIf="ActiveProfile.configStateParsed.configured"><strong class="text-success">Configured</strong></dd>
				  <dd class="col-sm-9" *ngIf="!ActiveProfile.configStateParsed.configured"><strong class="text-warning">In Progress</strong></dd>
				  <dt class="col-sm-3 text-right" *ngIf="ActiveProfile.configStateParsed.lastUpdated">Last Configured</dt>
				  <dd class="col-sm-9" *ngIf="ActiveProfile.configStateParsed.lastUpdated">{{ActiveProfile.configStateParsed.lastUpdated | date: 'long'}}</dd>
				  <dt class="col-sm-3 text-right" *ngIf="ActiveProfile.customData.description">Description</dt>
				  <dd class="col-sm-9">{{ActiveProfile.customData.description}}</dd>
				</dl>
			</div>

	    </ng-template>
	  </li>
	  <li ngbNavItem>
	    <a ngbNavLink>Inbound</a>
	    <ng-template ngbNavContent>
	    
			<div class="mt-3">
				<dl class="row mb-0">
				  <dt class="col-sm-3 ">Default Action</dt>
				  <dd class="col-sm-9">{{ActiveProfile.inbound.defaultAction | titlecase}} All</dd>
				</dl>
			</div>

			<hr> 

			<h6 class="">Exceptions</h6>
			
			<table class="table">
			  <thead>
			    <tr>
			      <th scope="col" style="width: 15%">Protocol</th>
			      <th scope="col" style="width: 15%">Port Range</th>
			      <th scope="col">Remote Address</th>
			      <th scope="col" style="width: 15%">Description</th>
			    </tr>
			  </thead>
			  <tbody>
			    <tr *ngFor="let Exception of ActiveProfile.inbound.exceptions">
			      <td>{{Exception.parsedProtocol | uppercase}}</td>
			      <td><span *ngIf="Exception.destPortRange">{{Exception.destPortRange.from}} - {{Exception.destPortRange.toInclusive}}</span></td>
			      <td><span *ngFor="let remoteAddress of Exception.remoteAddresses; let i = index">{{i ? ', ': ''}}{{remoteAddress}}</span>
			      <td>{{Exception.label}}</td>
			    </tr>
			    <tr *ngIf="ActiveProfile.inbound.exceptions.length < 1">
				    <td colspan="5" class="text-center">No Exceptions Configured</td>
			    </tr>
			  </tbody>
			</table>
	    
	    </ng-template>
	  </li>
	  <li ngbNavItem>
	  <li ngbNavItem>
	    <a ngbNavLink>Outbound</a>
	    <ng-template ngbNavContent>
	    
			<div class="mt-3">
				<dl class="row mb-0">
				  <dt class="col-sm-3 ">Default Action</dt>
				  <dd class="col-sm-9">{{ActiveProfile.outbound.defaultAction | titlecase}} All</dd>
				</dl>
			</div>

			<hr> 

			<h6 class="">Exceptions</h6>

			<table class="table">
			  <thead>
			    <tr>
			      <th scope="col" style="width: 15%">Protocol</th>
			      <th scope="col" style="width: 15%">Port Range</th>
			      <th scope="col">Remote Address</th>
			      <th scope="col" style="width: 15%">Description</th>
			    </tr>
			  </thead>
			  <tbody>
			    <tr *ngFor="let Exception of ActiveProfile.outbound.exceptions">
			      <td>{{Exception.parsedProtocol | uppercase}}</td>
			      <td><span *ngIf="Exception.destPortRange">{{Exception.destPortRange.from}} - {{Exception.destPortRange.toInclusive}}</span></td>
			      <td><span *ngFor="let remoteAddress of Exception.remoteAddresses; let i = index">{{i ? ', ': ''}}{{remoteAddress}}</span>
			      <td>{{Exception.label}}</td>
			    </tr>
			    <tr *ngIf="ActiveProfile.outbound.exceptions.length < 1">
				    <td colspan="5" style="text-center">No Exceptions Configured</td>
			    </tr>
			  </tbody>
			</table>
	    
	    </ng-template>
	  </li>
	  <li ngbNavItem>
	  </li>

	</ul>
	
	<div [ngbNavOutlet]="nav"></div>

  </div>
</div>
