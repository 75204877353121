<div style="position: relative" stTable #table="stTable" [ngClass]="{'list-preview-margin': showCard}">

	<div class="d-flex justify-content-between my-3">  

		<form class="flex-grow-1">
			<div class="form-row">
			    <div class="col">
			      <input type="text" class="form-control" placeholder="Filter Templates" stSearch="Template.Name">
			    </div>
			</div>
		</form>

		<div class="text-right ml-2">
			<a [routerLink]="['/template','new']" class="btn btn-primary"><fa-icon [icon]="faPlus" class="mr-1"></fa-icon> Create Template</a>
		</div>

	</div>

	<table class="table">
	  <thead>
	    <tr>
	      <th scope="col">Name</th>
	      <th scope="col"></th>
	    </tr>
	  </thead>
	  <tbody>
	    <tr *ngFor="let Template of table.items; let i = index">
	      <td>{{Template.Name}}</td>
	      <td class="text-right">
		  	<a [routerLink]="['/template',Template.id]" class="btn btn-sm btn-secondary mr-2"><fa-icon [icon]="faEdit" class="mr-1"></fa-icon> Modify</a>
	      </td>
	    </tr>
	  </tbody>
	</table>

	<div stPagination #pager="stPagination" class="d-flex justify-content-between">
		<div class="d-flex">
			<div *ngIf="table.busy; else pages" class="loading-indicator text-center" style="position: relative;"><div class="loading-spinner loading-spinner-small"></div></div>
			<ng-template #pages>
				<p>Showing items <em>{{pager.length > 0 ? pager.lowerBoundIndex + 1 : 0}}</em> - <em>{{pager.higherBoundIndex +
          1}}</em> of <em>{{pager.length}} </em> items
				</p>
			</ng-template>
		</div>

		<ul class="pagination">
			<li class="page-item" [ngClass]="{'disabled': !pager.isPreviousPageEnabled()}"><button class="page-link" (click)="pager.selectPreviousPage()" >Previous</button></li>
			<li class="page-item" [ngClass]="{'disabled': !pager.isNextPageEnabled()}"><button class="page-link" (click)="pager.selectNextPage()" >Next</button></li>
		</ul>
	</div>

</div>