	<div class="mt-1 mb-3">
		<a routerLink="/tag" class="font-weight-bold"><fa-icon [icon]="faChevronLeft"></fa-icon> Tags</a>
	</div>

	<div *ngIf="!isLoading; else loading">
		<div class="mb-4">
	    	<h3 *ngIf="tagID == 'new'">Create Tag</h3>
	    	<h3 *ngIf="tagID != 'new'">Update Tag</h3>
			<!--<p class="text-muted">Reassuring text that explains what this is and what you do.</p>-->
		</div>
	    
	    <form [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
	    
	    <div class="card mb-4">
		    <fieldset class="card-body">
			 
			<h5 class="card-title">Tag Details</h5>
			<!--<p class="text-muted">Reassuring text that explains what this is and what you do.</p>-->
			    
			  <div class="form-group row">
			    <label for="inputEmail3" class="col-sm-2 col-form-label">Name</label>
			    <div class="col-sm-10">
			      <input type="text" class="form-control" placeholder="Tag Name" formControlName="Name" required>
			    </div>
			  </div>

			  <div class="form-group row">
			    <label for="inputEmail3" class="col-sm-2 col-form-label">Color</label>
			    <div class="col-sm-10">
			      <color-twitter triangle="hide" (onChangeComplete)="handleChangeComplete($event)" [color]="Hex"></color-twitter>
			    </div>
			  </div>

		    </fieldset>
	    </div>
		
		<div class="my-3 text-right">
		    <button class="btn btn-success" type="submit" *ngIf="tagID == 'new'">
		    	<fa-icon [icon]="faSync" class="mr-1" [ngClass]="{'d-none' : !isUpdating}" [spin]="true"></fa-icon> 
		    	<fa-icon [icon]="faCheck" class="mr-1" [ngClass]="{'d-none' : isUpdating}"></fa-icon> 
				Create Tag
		    </button>
		    <button class="btn btn-success" type="submit" *ngIf="tagID != 'new'">
		    	<fa-icon [icon]="faSync" class="mr-1" [ngClass]="{'d-none' : !isUpdating}" [spin]="true"></fa-icon> 
		    	<fa-icon [icon]="faCheck" class="mr-1" [ngClass]="{'d-none' : isUpdating}"></fa-icon> 
		    	Update Tag
		    </button>
		</div>
	
	
	    </form>

	</div>
	
	<ng-template #loading>
		<div class="loading-spinner"></div>
	</ng-template>