import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent implements OnInit {

  @Input() name;

  faExclamationTriangle = faExclamationTriangle;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
