import { Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';

import { faEye, faWifi, faWifiSlash, faLock, faLockOpen } from '@fortawesome/pro-regular-svg-icons';

import { AppStateService } from './../app-state.service';
import { Pulsar4Service } from '../pulsar-4.service';

import { SmartTable, of, TableState } from 'smart-table-ng';
import { DefaultSettingsService } from './default-settings.service';
import server from 'smart-table-server';

import { trigger, state, style, animate, transition } from '@angular/animations';

import { Subscription } from 'rxjs';

const providers = [{
  provide: SmartTable,
  useFactory: (Pulsar: Pulsar4Service, settings: TableState) => of([], settings, server({
    query: (tableState) => Pulsar.getServicesTable(tableState)
  })),
  deps: [Pulsar4Service, DefaultSettingsService]
}];

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
  providers,
  animations: [
    trigger('fade', [      
      transition('void => *', [
        style({opacity: 0}),
        animate(200, style({opacity: 1}))
      ]),
      transition('* => void', [
        animate(200, style({opacity: 0}))
      ])
    ])
  ]
})

export class ServicesComponent implements OnInit {

  faEye = faEye;
  faWifi = faWifi; 
  faWifiSlash = faWifiSlash;
  faLock = faLock;
  faLockOpen = faLockOpen;

  Services: any = [];
  public isLoading = true; 
  public showCard = false; 

  selectedRow : Number = null;
  ActiveService: any = {};
 
  subscription: Subscription;

  @ViewChild('table', { static: false }) table;

  selectedAccountNumber = this.appStateService.getCurrentAccountNumber();

  constructor(private changeDetectorRef: ChangeDetectorRef, private appStateService: AppStateService, private pulsarService: Pulsar4Service) {}

  ngOnInit() {
    setTimeout(() => {
  	this.appStateService.showSidebar();
      }, 
    0);
    
	this.subscription = this.appStateService.getAccount().subscribe(data => { 
		this.isLoading = false;
		this.changeDetectorRef.detectChanges();
		if (data && this.selectedAccountNumber != data.accountNum) {
			this.selectedAccountNumber = data.accountNum;
			this.table.table.exec();
		}
    });
  }
  
  RowSelected(i, u, e) {
	  if (this.selectedRow == i) {
		  this.selectedRow = null;
		  this.ActiveService = {};	
		  this.showCard = false;
	  } else {
		  this.selectedRow = i;
		  this.ActiveService = u;
		  this.showCard = true;
		  
		  setTimeout(() => {
			  window.scrollTo({
			    top: e.offsetTop - 50, //add your necessary value
			    behavior: "smooth"  //Smooth transition to roll
			  });
		  });

	  }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

}
