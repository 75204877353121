import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

import { faEye } from '@fortawesome/pro-regular-svg-icons';

import { trigger, state, style, animate, transition } from '@angular/animations';

import isEmpty from 'lodash-es/isEmpty'
import * as moment from "moment";

import { Pulsar4Service } from '../pulsar-4.service';

@Component({
  selector: 'services-preview',
  templateUrl: './services-preview.component.html',
  styleUrls: ['./services-preview.component.scss'],
  animations: [
    trigger('fade', [      
      transition('void => *', [
        style({opacity: 0}),
        animate(200, style({opacity: 1}))
      ]),
      transition('* => void', [
        animate(200, style({opacity: 0}))
      ])
    ])
  ]
})
export class ServicesPreviewComponent implements OnInit, OnChanges {

  faEye = faEye;
  moment = moment;
  
  public showCard = false; 
  public isLoading = true;  

  public ServiceDetail: any = {};
  public Attributes: any = {};
  
  serviceID: number; 

  @Input() Service: {};

  constructor(private pulsarService: Pulsar4Service) {}

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
	  
	this.showCard = !isEmpty(this.Service);
	
	if (this.showCard) {
		this.GrabService(this.Service['serviceID']);
	}

  }


  public GrabService(serviceID) {

	  this.isLoading = true;
	  this.pulsarService.getServicePreview(serviceID).subscribe(Service => {
	  	this.ServiceDetail = Service.Service;
	  	this.Attributes = Service.Attributes;
	  	this.isLoading = false;
	  });    	  

  }

}
