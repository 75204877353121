import { Component, OnInit, OnDestroy } from '@angular/core';

import { AppStateService } from './../app-state.service';

import { faSatellite, faEnvelope, faEnvelopeOpenText, faWifi, faWifiSlash, faEye, faSatelliteDish, faCheckCircle, faMailbox } from '@fortawesome/pro-regular-svg-icons';

import { Pulsar4Service } from '../pulsar-4.service';

import { Subscription } from 'rxjs';

import { OAuthService } from 'angular-oauth2-oidc';
import { AuthFunction } from '../shared';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  faSatellite = faSatellite;
  faEnvelope = faEnvelope;
  faEnvelopeOpenText = faEnvelopeOpenText; 
  faWifi = faWifi;
  faWifiSlash = faWifiSlash;
  faEye = faEye; 
  faSatelliteDish = faSatelliteDish;
  faCheckCircle = faCheckCircle;
  faMailbox = faMailbox;

  subscription: Subscription;

  public isLoading = true;  

  Dashboard: any = {};
  Account: any = {};

  constructor(private oauthService: OAuthService, private auth: AuthFunction, private appStateService: AppStateService, private pulsarService: Pulsar4Service) {}

  ngOnInit() {

	if (!this.auth.isLoggedin) {
		this.oauthService.initImplicitFlow();
	} else {

	    setTimeout(() => {
	  	this.appStateService.showSidebar();
	      }, 
	    0);
	    
	    this.subscription = this.appStateService.getAccount().subscribe(data => { 
		    if (data) {
		        this.Account = data;
		        this.GrabDashboard(this.Account.accountNum);
		    }
	    });

	}

  }

  public GrabDashboard(accountNum) {
	  this.isLoading = true;
	  
	  this.pulsarService.getDashboard(accountNum).subscribe(Dashboard => {
	  	this.Dashboard = Dashboard;
	  	this.isLoading = false;
	  });    	  
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

}
