<nav class="navbar navbar-dark fixed-top bg-pivotel p-0 shadow navbar-expand-md">
  <a class="navbar-brand col-sm-3 col-md-2 mr-0 p-2 pl-3" routerLink="home">
	  <img src="assets/logo.svg" alt="Pulsar Logo" style="height: 40px;">
  </a>
  <ul class="navbar-nav px-3 ml-auto">
    <li class="nav-item text-nowrap" ngbDropdown *ngIf="isLoggedin">
      <a id="nav-user-logout" class="nav-link" [routerLink]="" ngbDropdownToggle>
	      {{appStateService.getUser()['displayName']}}
	      <div id="nav-user" class="rounded-circle">
		  	<fa-icon [icon]="faUser" size="md"></fa-icon> 
	      </div>
      </a>
      <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
        <button ngbDropdownItem routerLink="/logout">Logout</button>
      </div>
    </li>
  </ul>
</nav>

<div class="container-fluid">
	<div class="row">

		<nav class="col-md-2 d-none d-md-block bg-light sidebar" *ngIf="isSidebarVisible">
          <div class="sidebar-sticky">
	          
	        <div class="p-3 mb-2" style="border-bottom: 1px solid rgba(0,0,0,.05)">
			    <select class="form-control" [(ngModel)]="selectedAccountNumber" (ngModelChange)="onChangeAccount($event)">
				  <option *ngFor="let Account of Accounts" [value]="Account.accountNum">{{Account.fullName}}</option>
			    </select>
	        </div>

            <ul class="nav flex-column pb-3" style="border-bottom: 1px solid rgba(0,0,0,.05)">
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/home']" 
                  [routerLinkActive]="['active']"
                  [routerLinkActiveOptions]="{ exact: true }">
				  <fa-icon [icon]="faHome" [fixedWidth]="true"></fa-icon>
                  Dashboard
                </a>
              </li>

              <li class="nav-item" >
                <a class="nav-link" routerLink="/service" routerLinkActive="active">
				  <fa-icon [icon]="faSatelliteDish" [fixedWidth]="true"></fa-icon>
                  Services &amp; Devices
                </a>
              </li>

              <li class="nav-item" >
                <a class="nav-link" routerLink="/template" routerLinkActive="active">
				  <fa-icon [icon]="faFileExport" [fixedWidth]="true"></fa-icon>
                  Templates
                </a>
              </li>

              <li class="nav-item" >
                <a class="nav-link" routerLink="/tag" routerLinkActive="active">
				  <fa-icon [icon]="faTags" [fixedWidth]="true"></fa-icon>
                  Tags
                </a>
              </li>

            </ul>

            <ul class="nav flex-column pb-3" style="border-bottom: 1px solid rgba(0,0,0,.05)">

			  <li class="nav-item mt-2" style="padding: 0.5rem 1rem;"><strong class="text-muted">Profiles</strong></li>

              <li class="nav-item">
                <a class="nav-link" routerLink="/alert-profile" routerLinkActive="active">
				  <fa-icon [icon]="faBells" [fixedWidth]="true"></fa-icon>
                  Alerting
                </a>
              </li>

              <li class="nav-item">
                <a class="nav-link" routerLink="/bar-profile" routerLinkActive="active">
				  <fa-icon [icon]="faLock" [fixedWidth]="true"></fa-icon>
                  Barring
                </a>
              </li>

              <li class="nav-item">
                <a class="nav-link" routerLink="/firewall-profile" routerLinkActive="active">
				  <fa-icon [icon]="faChessRook" [fixedWidth]="true"></fa-icon>
                  Firewall
                </a>
              </li>

              <!--<li class="nav-item">
                <a class="nav-link" routerLink="/domains" routerLinkActive="active">
				  <fa-icon [icon]="faGlobe" [fixedWidth]="true"></fa-icon>
                  Domain Filter
                </a>
              </li>-->

            </ul>

            <ul class="nav flex-column">

			  <li class="nav-item mt-2" style="padding: 0.5rem 1rem;"><strong class="text-muted">Account Management</strong></li>

			  <li class="nav-item">
                <a class="nav-link" [href]="'https://auth.pivotel.com.au/pulsar4/users-redirect?account=' + selectedAccountNumber" target="_blank">
				  <fa-icon [icon]="faUsers" [fixedWidth]="true"></fa-icon>
                  Users <fa-icon [icon]="faExternalLink" [fixedWidth]="true"></fa-icon>
                </a>
              </li>

              <!--<li class="nav-item">
                <a class="nav-link" routerLink="/users" routerLinkActive="active">
				  <fa-icon [icon]="faUsers" [fixedWidth]="true"></fa-icon>
                  Users
                </a>
              </li>

              <li class="nav-item">
                <a class="nav-link" routerLink="/notifications" routerLinkActive="active">
				  <fa-icon [icon]="faMailbox" [fixedWidth]="true"></fa-icon>
                  Notifications
                </a>
              </li>-->

            </ul>

          </div>

        </nav>

		<main role="main" class="px-4" [ngClass]="{'ml-sm-auto col-md-9 col-lg-10': isSidebarVisible}">
			<router-outlet></router-outlet>
		</main>
	</div>
</div>