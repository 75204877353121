
import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';

import { faPlus, faEdit, faCopy, faTrash, faSatelliteDish } from '@fortawesome/pro-regular-svg-icons';

import { AppStateService } from './../app-state.service';
import { Pulsar4Service } from '../pulsar-4.service';

import { SmartTable, of, TableState } from 'smart-table-ng';
import { DefaultSettingsService } from './default-settings.service';
import server from 'smart-table-server';

import { trigger, state, style, animate, transition } from '@angular/animations';

import { Subscription } from 'rxjs';

const providers = [{
  provide: SmartTable,
  useFactory: (Pulsar: Pulsar4Service, settings: TableState) => of([], settings, server({
    query: (tableState) => Pulsar.getTemplatesTable(tableState)
  })),
  deps: [Pulsar4Service, DefaultSettingsService]
}];

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss'],
  providers,
  animations: [
    trigger('fade', [      
      transition('void => *', [
        style({opacity: 0}),
        animate(200, style({opacity: 1}))
      ]),
      transition('* => void', [
        animate(200, style({opacity: 0}))
      ])
    ])
  ]
})

export class TemplatesComponent implements OnInit {

  faPlus = faPlus;
  faEdit = faEdit;
  faCopy = faCopy; 
  faTrash = faTrash;
  faSatelliteDish = faSatelliteDish;

  Profiles: any = [];
  public isLoading = true; 
  public showCard = false; 

  selectedRow : Number = null;
  ActiveProfile: any = [];

  subscription: Subscription;

  @ViewChild('table', { static: false }) table;

  selectedAccountNumber = this.appStateService.getCurrentAccountNumber();

  constructor(private changeDetectorRef: ChangeDetectorRef, private appStateService: AppStateService) {
  }

  ngOnInit() {
    setTimeout(() => {
  	this.appStateService.showSidebar();
      }, 
    0);

	this.subscription = this.appStateService.getAccount().subscribe(data => { 
		this.isLoading = false;
		this.changeDetectorRef.detectChanges();
		if (data && this.selectedAccountNumber != data.accountNum) {
			this.selectedAccountNumber = data.accountNum;
			this.table.table.exec();
		}
    });
  }

}
