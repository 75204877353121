import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap, switchMap, mergeMap, concatMap } from 'rxjs/operators';

import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { TableState } from 'smart-table-ng';

import { AppStateService } from './app-state.service';

import { ErrorModalComponent } from './error-modal/error-modal.component';

import { environment } from '../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

interface Summary {
  page: number;
  size: number;
  filteredCount: number;
}

interface ServerResult {
  data: any[];
  summary: Summary
}

@Injectable({
  providedIn: 'root'
})
export class Pulsar4Service {

  private apiUrl = environment.apiUrl;  // URL to web api

  results:Object[];
  loading:boolean;
  
  protected conf: any = {};
  protected data: any = {};
  protected tagsModel: any = [];

  constructor(private http: HttpClient, private modalService: NgbModal, public appStateService: AppStateService) { }

	/* Dashboard */
    getDashboard(accountNum): Observable<any> {
      return this.http.get(this.apiUrl + '/' + accountNum + '/Dashboard')
        .pipe(
          tap(Profiles => this.log('Fetched Dashboard')),
          catchError(this.handleError('getDashboard', []))
      );
    }

    getUser(): Observable<any> {
      return this.http.get(this.apiUrl + '/User/')
        .pipe(
          tap(User => this.log('Fetched User')),
          catchError(this.handleError('getUser', []))
      );
    }

	/* Services */
    getServicesTable(tableState: TableState): Promise<ServerResult> {
      return this.getServices(this.appStateService.getCurrentAccountNumber(), this.queryString(tableState))
      .pipe(map(res => {
        this.data = res;
        return {
          data: this.data.Services,
		  summary: this.data.Summary
        }
      })).toPromise();
    }

    getServices(accountNum, queryString): Observable<any> {
      return this.http.get(this.apiUrl + '/' + accountNum + '/Services/' + queryString)
        .pipe(
          tap(Services => this.log('Fetched Services')),
          catchError(this.handleError('getServices', []))
      );
    }
  
    getService(ServiceID): Observable<any> {
      return this.http.get(this.apiUrl + '/' + this.appStateService.getCurrentAccountNumber() + '/Services/' + ServiceID)
        .pipe(
          tap(Service => this.log('Fetched Service ' + ServiceID)),
          catchError(this.handleError('getService', []))
      );
    }

    getServicePreview(ServiceID): Observable<any> {
      return this.http.get(this.apiUrl + '/' + this.appStateService.getCurrentAccountNumber() + '/Services/' + ServiceID + '/Preview')
        .pipe(
          tap(Service => this.log('Fetched Service ' + ServiceID)),
          catchError(this.handleError('getService', []))
      );
    }

    putServiceDisplayName(ServiceID, DisplayName): Observable<any> {
      return this.http.put(this.apiUrl + '/' + this.appStateService.getCurrentAccountNumber() + '/Services/' + ServiceID + '/DisplayName', DisplayName)
        .pipe(
          tap(Service => this.log('Put Service Display Name ' + ServiceID)),
          catchError(this.handleError('putServiceDisplayName', []))
      );
    }

    putServiceDescription(ServiceID, Description): Observable<any> {
      return this.http.put(this.apiUrl + '/' + this.appStateService.getCurrentAccountNumber() + '/Services/' + ServiceID + '/Description', Description)
        .pipe(
          tap(Service => this.log('Put Service Description ' + ServiceID)),
          catchError(this.handleError('putServiceDescription', []))
      );
    }

    putServiceTags(ServiceID, Tags): Observable<any> {
      return this.http.put(this.apiUrl + '/' + this.appStateService.getCurrentAccountNumber() + '/Services/' + ServiceID + '/Tags', Tags)
        .pipe(
          tap(Service => this.log('Put Service Tags ' + ServiceID)),
          catchError(this.handleError('putServiceTags', []))
      );
    }

    putServiceAlertingProfiles(ServiceID, Profiles): Observable<any> {
      return this.http.put(this.apiUrl + '/' + this.appStateService.getCurrentAccountNumber() + '/Services/' + ServiceID + '/Profile/Alert', Profiles)
        .pipe(
          tap(Service => this.log('Put Service Tags ' + ServiceID)),
          catchError(this.handleError('putServiceAlertingProfiles', []))
      );
    }

    putServiceBarringProfiles(ServiceID, Profiles): Observable<any> {
      return this.http.put(this.apiUrl + '/' + this.appStateService.getCurrentAccountNumber() + '/Services/' + ServiceID + '/Profile/Bar', Profiles)
        .pipe(
          tap(Service => this.log('Put Service Tags ' + ServiceID)),
          catchError(this.handleError('putServiceBarringProfiles', []))
      );
    }

    putServiceFirewallProfiles(ServiceID, Profiles): Observable<any> {
      return this.http.put(this.apiUrl + '/' + this.appStateService.getCurrentAccountNumber() + '/Services/' + ServiceID + '/Profile/Firewall', Profiles)
        .pipe(
          tap(Service => this.log('Put Service Tags ' + ServiceID)),
          catchError(this.handleError('putServiceFirewallProfiles', []))
      );
    }

    putServiceUnbar(ServiceID): Observable<any> {
      return this.http.put(this.apiUrl + '/' + this.appStateService.getCurrentAccountNumber() + '/Services/' + ServiceID + '/Unbar', '')
        .pipe(
          tap(Service => this.log('Put Service Unbar ' + ServiceID)),
          catchError(this.handleError('putServiceUnbar', []))
      );
    }

    putServiceBar(ServiceID): Observable<any> {
      return this.http.put(this.apiUrl + '/' + this.appStateService.getCurrentAccountNumber() + '/Services/' + ServiceID + '/Bar', '')
        .pipe(
          tap(Service => this.log('Put Service Bar ' + ServiceID)),
          catchError(this.handleError('putServiceBar', []))
      );
    }

    /* Templates */
    getTemplatesTable(tableState: TableState): Promise<ServerResult> {
      return this.getTemplates(this.appStateService.getCurrentAccountNumber(), this.queryString(tableState))
      .pipe(map(res => {
        this.data = res;
        return {
          data: this.data.Templates,
		  summary: this.data.Summary
        }
      })).toPromise();
    }

    getTemplates(accountNum, queryString): Observable<any> {
      return this.http.get(this.apiUrl + '/' + accountNum + '/Templates/' + queryString)
        .pipe(
          tap(Services => this.log('Fetched Template')),
          catchError(this.handleError('getTemplates', []))
      );
    }

    getTemplate(TemplateID): Observable<any> {
      return this.http.get(this.apiUrl + '/' + this.appStateService.getCurrentAccountNumber() + '/Templates/' + TemplateID)
        .pipe(
          tap(Service => this.log('Fetched Template ' + TemplateID)),
          catchError(this.handleError('getTemplate', []))
      );
    }

    postTemplate(TemplateData): Observable<any> {
      return this.http.post(this.apiUrl + '/' + this.appStateService.getCurrentAccountNumber() + '/Templates/', TemplateData)
        .pipe(
          tap(Service => this.log('Post Template ')),
          catchError(this.handleError('postTemplate', []))
      );
    }

    putTemplate(TemplateID, TemplateData): Observable<any> {
      return this.http.put(this.apiUrl + '/' + this.appStateService.getCurrentAccountNumber() + '/Templates/' + TemplateID, TemplateData)
        .pipe(
          tap(Service => this.log('Put Template ' + TemplateID)),
          catchError(this.handleError('putTemplate', []))
      );
    }
    
    /* Tags */
    getTagsTable(tableState: TableState): Promise<ServerResult> {
      return this.getTags(this.appStateService.getCurrentAccountNumber(), this.queryString(tableState))
      .pipe(map(res => {
        this.data = res;
        return {
          data: this.data.Tags,
		  summary: this.data.Summary
        }
      })).toPromise();
    }

    getTagsAutocomplete(accountNum, queryString): Observable<any> {
      return this.http.get(this.apiUrl + '/' + accountNum + '/Tags/' + queryString)
        .pipe(
          map(res => {
          	this.data = res;
          	
          	this.data.Tags.forEach(Tag => {
			  this.tagsModel.push({
				  value: Tag.uuid, 
				  display: Tag.Name,
				  hex: Tag.Hex
			  });
          	});		  	

          	return this.tagsModel;
          }),
          tap(Services => this.log('Fetched Tags')),
          catchError(this.handleError('getTags', []))
      );
    }

    getTags(accountNum, queryString): Observable<any> {
      return this.http.get(this.apiUrl + '/' + accountNum + '/Tags/' + queryString)
        .pipe(
          tap(Services => this.log('Fetched Tags')),
          catchError(this.handleError('getTags', []))
      );
    }

    getTag(TagID): Observable<any> {
      return this.http.get(this.apiUrl + '/' + this.appStateService.getCurrentAccountNumber() + '/Tags/' + TagID)
        .pipe(
          tap(Service => this.log('Fetched Tag ' + TagID)),
          catchError(this.handleError('getTag', []))
      );
    }

    postTag(TagData): Observable<any> {
      return this.http.post(this.apiUrl + '/' + this.appStateService.getCurrentAccountNumber() + '/Tags/', TagData)
        .pipe(
          tap(Service => this.log('Post Tag ')),
          catchError(this.handleError('postTag', []))
      );
    }

    putTag(TagID, TagData): Observable<any> {
      return this.http.put(this.apiUrl + '/' + this.appStateService.getCurrentAccountNumber() + '/Tags/' + TagID, TagData)
        .pipe(
          tap(Service => this.log('Put Tag ' + TagID)),
          catchError(this.handleError('putTag', []))
      );
    }

	/* Alerting Profiles */
    getAlertingProfilesTable(tableState: TableState): Promise<ServerResult> {
      return this.getAlertingProfiles(this.appStateService.getCurrentAccountNumber(), this.queryString(tableState))
      .pipe(map(res => {
        this.data = res;
        return {
          data: this.data.Profiles,
		  summary: this.data.Summary
        }
      })).toPromise();
    }

    getAlertingProfiles(accountNum, queryString): Observable<any> {
      return this.http.get(this.apiUrl + '/' + accountNum + '/Profiles/Alert' + queryString)
        .pipe(
          tap(Profiles => this.log('Fetched Profiles')),
          catchError(this.handleError('getAlertingProfiles', []))
      );
    }

    getAlertingProfilesAutocomplete(accountNum, queryString): Observable<any> {
      return this.http.get(this.apiUrl + '/' + accountNum + '/Profiles/Alert' + queryString)
        .pipe(
          map(res => {
          	this.data = res;
          	
          	this.data.Profiles.forEach(Tag => {
			  this.tagsModel.push({
				  value: Tag.id, 
				  display: Tag.name
			  });
          	});		  	

          	return this.tagsModel;
          }),
          tap(Profiles => this.log('Fetched Profiles')),
          catchError(this.handleError('getAlertingProfiles', []))
      );
    }

    getAlertingProfile(ProfileID): Observable<any> {
      return this.http.get(this.apiUrl + '/' + this.appStateService.getCurrentAccountNumber() + '/Profiles/Alert/' + ProfileID)
        .pipe(
          tap(Profiles => this.log('Fetched Profiles')),
          catchError(this.handleError('getAlertingProfiles', []))
      );
    }

    postAlertingProfile(ProfileData): Observable<any> {
      return this.http.post(this.apiUrl + '/' + this.appStateService.getCurrentAccountNumber() + '/Profiles/Alert/', ProfileData)
        .pipe(
          tap(Service => this.log('Post Alerting Profile')),
          catchError(this.handleError('postAlertingProfile', []))
      );
    }

    putAlertingProfile(ProfileID, ProfileData): Observable<any> {
      return this.http.put(this.apiUrl + '/' + this.appStateService.getCurrentAccountNumber() + '/Profiles/Alert/' + ProfileID, ProfileData)
        .pipe(
          tap(Service => this.log('Put Alerting Profile ' + ProfileID)),
          catchError(this.handleError('putAlertingProfile', []))
      );
    }

    deleteAlertingProfile(ProfileID): Observable<any> {
      return this.http.delete(this.apiUrl + '/' + this.appStateService.getCurrentAccountNumber() + '/Profiles/Alert/' + ProfileID)
        .pipe(
          tap(Service => this.log('Delete Alerting Profile ' + ProfileID)),
          catchError(this.handleError('deleteAlertingProfile', []))
      );
    }

	/* Barring Profiles */

    getBarringProfilesTable(tableState: TableState): Promise<ServerResult> {
      return this.getBarringProfiles(this.appStateService.getCurrentAccountNumber(), this.queryString(tableState))
      .pipe(map(res => {
        this.data = res;
        return {
          data: this.data.Profiles,
		  summary: this.data.Summary
        }
      })).toPromise();
    }

    getBarringProfiles(accountNum, queryString): Observable<any> {
      return this.http.get(this.apiUrl + '/' + accountNum + '/Profiles/Bar' + queryString)
        .pipe(
          tap(Profiles => this.log('Fetched Profiles')),
          catchError(this.handleError('getBarringProfiles', []))
      );
    }

    getBarringProfilesAutocomplete(accountNum, queryString): Observable<any> {
      return this.http.get(this.apiUrl + '/' + accountNum + '/Profiles/Bar' + queryString)
        .pipe(
          map(res => {
          	this.data = res;
          	
          	this.data.Profiles.forEach(Tag => {
			  this.tagsModel.push({
				  value: Tag.id, 
				  display: Tag.name
			  });
          	});		  	

          	return this.tagsModel;
          }),
          tap(Profiles => this.log('Fetched Profiles')),
          catchError(this.handleError('getBarringProfiles', []))
      );
    }

    getBarringProfile(ProfileID): Observable<any> {
      return this.http.get(this.apiUrl + '/' + this.appStateService.getCurrentAccountNumber() + '/Profiles/Bar/' + ProfileID)
        .pipe(
          tap(Profiles => this.log('Fetched Profiles')),
          catchError(this.handleError('getBarringProfiles', []))
      );
    }

    postBarringProfile(ProfileData): Observable<any> {
      return this.http.post(this.apiUrl + '/' + this.appStateService.getCurrentAccountNumber() + '/Profiles/Bar/', ProfileData)
        .pipe(
          tap(Service => this.log('Post Barring Profile')),
          catchError(this.handleError('postBarringProfile', []))
      );
    }

    putBarringProfile(ProfileID, ProfileData): Observable<any> {
      return this.http.put(this.apiUrl + '/' + this.appStateService.getCurrentAccountNumber() + '/Profiles/Bar/' + ProfileID, ProfileData)
        .pipe(
          tap(Service => this.log('Put Barring Profile ' + ProfileID)),
          catchError(this.handleError('putBarringProfile', []))
      );
    }

    deleteBarringProfile(ProfileID): Observable<any> {
      return this.http.delete(this.apiUrl + '/' + this.appStateService.getCurrentAccountNumber() + '/Profiles/Alert/' + ProfileID)
        .pipe(
          tap(Service => this.log('Delete Barring Profile ' + ProfileID)),
          catchError(this.handleError('deleteBarringProfile', []))
      );
    }

    /* Firewall Profiles */
    getFirewallProfilesTable(tableState: TableState): Promise<ServerResult> {
      return this.getFirewallProfiles(this.appStateService.getCurrentAccountNumber(), this.queryString(tableState))
      .pipe(map(res => {
        this.data = res;
        return {
          data: this.data.Profiles,
		  summary: this.data.Summary
        }
      })).toPromise();
    }

    getFirewallProfiles(accountNum, queryString): Observable<any> {
      return this.http.get(this.apiUrl + '/' + accountNum + '/Firewall/Profiles/' + queryString)
        .pipe(
          tap(Profiles => this.log('Fetched Profiles')),
          catchError(this.handleError('getFirewallProfiles', []))
      );
    }

    getFirewallProfilesAutocomplete(accountNum, queryString): Observable<any> {
      return this.http.get(this.apiUrl + '/' + accountNum + '/Firewall/Profiles/' + queryString)
        .pipe(
          map(res => {
          	this.data = res;
          	
          	this.data.Profiles.forEach(Tag => {
			  this.tagsModel.push({
				  value: Tag.uid, 
				  display: Tag.label
			  });
          	});		  	

          	return this.tagsModel;
          }),
          tap(Profiles => this.log('Fetched Profiles')),
          catchError(this.handleError('getFirewallProfiles', []))
      );
    }

    getFirewallProfile(ProfileID): Observable<any> {
      return this.http.get(this.apiUrl + '/' + this.appStateService.getCurrentAccountNumber() + '/Firewall/Profiles/' + ProfileID)
        .pipe(
          tap(Service => this.log('Fetched Profile ' + ProfileID)),
          catchError(this.handleError('getFirewallProfile', []))
      );
    }

    postFirewallProfile(ProfileData): Observable<any> {
      return this.http.post(this.apiUrl + '/' + this.appStateService.getCurrentAccountNumber() + '/Firewall/Profiles/', ProfileData)
        .pipe(
          tap(Service => this.log('Post Tag ')),
          catchError(this.handleError('postFirewallProfile', []))
      );
    }

    putFirewallProfile(ProfileID, ProfileData): Observable<any> {
      return this.http.put(this.apiUrl + '/' + this.appStateService.getCurrentAccountNumber() + '/Firewall/Profiles/' + ProfileID, ProfileData)
        .pipe(
          tap(Service => this.log('Put Firewall Profile ' + ProfileID)),
          catchError(this.handleError('putFirewallProfile', []))
      );
    }

    private queryString(tableState: TableState) {

	  this.conf.page = (tableState.slice['page'] - 1);
	  this.conf.sort = (tableState.sort['pointer']);
	  this.conf.direction = (tableState.sort['direction']);
	  
	  if (tableState.search['value']) {
		  return '?page=' + this.conf.page + '&size=20&sort=' + this.conf.sort + ',' + this.conf.direction + '&search=' + tableState.search['value'];
	  }
	  
	  return '?page=' + this.conf.page + '&size=20&sort=' + this.conf.sort + ',' + this.conf.direction;
	  
    }

    private handleError<T> (operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {
  
      // TODO: better job of transforming error for Subscriber consumption
      this.log(`${operation} failed: ${error.message}`);
 
      let options: NgbModalOptions = {
        size: 'lg'
      };
 
	  //this.modalService.open(`${operation} failed: ${error.message}. ${error.error.Detail}`, options);
 
	  const modalRef = this.modalService.open(ErrorModalComponent, options);
	  modalRef.componentInstance.name = operation;

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
    
  }
 
  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    console.log(`Pulsar4Service: ${message}`);
  }

}
