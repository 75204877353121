import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class AuthFunction {

  constructor(private oauthService: OAuthService) {}

  public get isLoggedin() {
    if (this.oauthService.hasValidIdToken()) {
      return true;
    }
    
    return false;
  }
  public get Permissions() {
    let claims = this.oauthService.getIdentityClaims();
    if (!claims) return null;
    claims['permissions'] = claims['permissions'];
    return claims['permissions'];
  }

}
