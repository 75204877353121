<div stTable #table="stTable" *ngIf="!isLoading" [ngClass]="{'list-preview-margin': showCard}">
	
	<div class="d-flex justify-content-between my-3">  

		<form class="flex-grow-1">
			<div class="form-row">
			    <div class="col">
			      <input type="text" class="form-control" placeholder="Filter Services" stSearch="Service.displayName">
			    </div>
			</div>
		</form>

	</div>

	<table class="table">
	  <thead>
	    <tr>
	      <th scope="col">Name</th>
	      <th scope="col">Type</th>
	      <th scope="col">MSISDN</th>
	      <th scope="col">ICCID</th>
	      <th scope="col" class="text-center" colspan="2">Status</th>
	    </tr>
	  </thead>
	  <tbody>
	    <tr style="background-color: lightgrey;">
	    <tr *ngFor="let Service of table.items; let i = index" (click)="RowSelected(i, Service, ServiceRow);" [class.table-active]="i === selectedRow" #ServiceRow class="table-select">
	      <td class="align-middle" width="25%">
		      <strong>{{Service.displayName ? Service.displayName : Service.serviceNum}}</strong>
		  </td>
	      <td class="align-middle">{{Service.serviceType}}</td>
	      <td class="align-middle">{{Service.msisdn}}</td>
	      <td class="align-middle">{{Service.sim}}</td>
	      <td class="align-middle text-center">
		      <span class="text-success" *ngIf="Service.networkStatus == 'online'"><fa-icon [icon]="faWifi" [fixedWidth]="true"></fa-icon></span>
		      <span class="text-danger" *ngIf="Service.networkStatus == 'offline'"><fa-icon [icon]="faWifiSlash" [fixedWidth]="true"></fa-icon></span>
		  </td>
	      <td class="align-middle text-center">
		      <span class="text-success" *ngIf="Service.dataStatus == 'Unbarred'"><fa-icon [icon]="faLockOpen" [fixedWidth]="true"></fa-icon></span>
		      <span class="text-danger" *ngIf="Service.dataStatus != 'Unbarred'"><fa-icon [icon]="faLock" [fixedWidth]="true"></fa-icon></span>
		  </td>
	    </tr>
	  </tbody>
	</table>

	<div stPagination #pager="stPagination" class="d-flex justify-content-between">
		<div class="d-flex">
			<div *ngIf="table.busy; else pages" class="loading-indicator text-center" style="position: relative;"><div class="loading-spinner loading-spinner-small"></div></div>
			<ng-template #pages>
				<p>Showing items <em>{{pager.length > 0 ? pager.lowerBoundIndex + 1 : 0}}</em> - <em>{{pager.higherBoundIndex +
          1}}</em> of <em>{{pager.length}} </em> items
				</p>
			</ng-template>
		</div>

		<ul class="pagination">
			<li class="page-item" [ngClass]="{'disabled': !pager.isPreviousPageEnabled()}"><button class="page-link" (click)="pager.selectPreviousPage()" >Previous</button></li>
			<li class="page-item" [ngClass]="{'disabled': !pager.isNextPageEnabled()}"><button class="page-link" (click)="pager.selectNextPage()" >Next</button></li>
		</ul>
	</div>

</div>

<services-preview [Service]="ActiveService"></services-preview>