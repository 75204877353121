import { Component, OnInit } from '@angular/core';

import { OAuthService } from 'angular-oauth2-oidc';
import { AppStateService } from './../app-state.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private oauthService: OAuthService, private appStateService: AppStateService) { }

  ngOnInit(): void {
	  
    setTimeout(() => {
  	this.appStateService.hideSidebar();
      }, 
    0);

	this.oauthService.logOut();

  }

}
