	<div class="mt-1 mb-3">
		<a routerLink="/alert-profile" class="font-weight-bold"><fa-icon [icon]="faChevronLeft"></fa-icon> Alerting</a>
	</div>

	<div *ngIf="!isLoading; else loading">
		<div class="mb-4">
	    	<h3 *ngIf="profileID == 'new'">Create Alert Profile</h3>
	    	<h3 *ngIf="profileID != 'new'">Update Alert Profile</h3>
			<!--<p class="text-muted">Reassuring text that explains what this is and what you do.</p>-->
		</div>
	    
	    <form [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
	    
	    <div class="card mb-4">
		    <fieldset class="card-body">
			 
			<h5 class="card-title">1. Profile Details</h5>
			<!--<p class="text-muted">Reassuring text that explains what this is and what you do.</p>-->
			    
			  <div class="form-group row">
			    <label for="inputEmail3" class="col-sm-2 col-form-label">Name</label>
			    <div class="col-sm-10">
			      <input type="text" class="form-control" placeholder="Profile Name" formControlName="name">
			    </div>
			  </div>
	
			  <!--<div class="form-group row">
			    <label for="inputEmail3" class="col-sm-2 col-form-label">Description</label>
			    <div class="col-sm-10">
			      <textarea class="form-control" id="inputEmail3"></textarea>
			    </div>
			  </div>-->
	
			  <div class="form-group row">
			    <label for="inputEmail3" class="col-sm-2 col-form-label">Status</label>
			    <div class="col-sm-10 py-2">
					<div class="form-check form-check-inline">
					  <input class="form-check-input" type="radio" id="name-enabled" value="Enabled" formControlName="status">
					  <label class="form-check-label" for="name-enabled">Enabled</label>
					</div>			      
					<div class="form-check form-check-inline">
					  <input class="form-check-input" type="radio" id="name-disabled" value="Disabled" formControlName="status">
					  <label class="form-check-label" for="name-disabled">Disabled</label>
					</div>
			    </div>
			  </div>
	
	
		    </fieldset>
	    </div>
	
	    <div class="card mb-4">
		    <fieldset class="card-body">
			 
			<h5 class="card-title">2. Conditions</h5>
			<!--<p class="text-muted">Reassuring text that explains what this is and what you do.</p>-->
	
			  <div class="form-group row mb-0">
	
			    <!--<div class="col-sm-2">
			      <label>Monitor</label>
			    </div>-->
	
			    <div class="col-sm-4">
			      <label>When</label>
			    </div>
	
				<div class="col-sm-4">
			      <label>exceeds</label> 
				</div>
			    
				<div class="col-sm-1 offset-sm-1">
				</div>
				
			  </div>
	
			  <div class="form-group row" formArrayName="conditions" *ngFor="let condition of myForm.controls.conditions?.controls; let i = index">

			    <ng-container [formGroupName]="i">

				    <!--<div class="col-sm-2">
				      <select id="inputState" class="form-control">
				        <option>Data</option>
				        <option>Money</option>
				      </select>
				    </div>-->
		
				    <div class="col-sm-4">
				      <select class="form-control" formControlName="Action">
				        <option value="VOLUME">Volume</option>
				        <option value="TRACKER">Volume Tracker</option>
				        <option value="PERCENTAGE">Allowance</option>
				        <option value="ACTION">Service is Barred</option>
				      </select>
				    </div>
				    		
					<div class="col-sm-4">
						
						<div class="input-group mb-3" *ngIf="myForm.value.conditions[i].Action == 'VOLUME'">
						  <input type="text" class="form-control" formControlName="Volume">
						  <div class="input-group-append">
						    <span class="input-group-text">MB</span>
						  </div>
						</div>

						<div class="input-group mb-3" *ngIf="myForm.value.conditions[i].Action == 'TRACKER'">
						  <input type="text" class="form-control" formControlName="Volume">
						  <div class="input-group-append">
						    <span class="input-group-text">MB</span>
						  </div>
						  <div class="input-group-prepend">
						    <span class="input-group-text">then every</span>
						  </div>
						  <input type="text" class="form-control" formControlName="Tracker">
						  <div class="input-group-append">
						    <span class="input-group-text">MB</span>
						  </div>
						</div>


						<div class="input-group mb-3" *ngIf="myForm.value.conditions[i].Action == 'PERCENTAGE'">
						  <input type="text" class="form-control" formControlName="Percentage">
						  <div class="input-group-append">
						    <span class="input-group-text">%</span>
						  </div>
						</div>
						
				    </div>
		
					<div class="col-sm-1 offset-sm-3 text-right">
						<button (click)="deleteConditionFormGroup(i)" class="btn btn-danger" type="button"><fa-icon [icon]="faTrash" class="" [fixedWidth]="true"></fa-icon></button>
					</div>

			    </ng-container>
				
			  </div>

			  <div class="form-group row">
			    
				<div class="col-sm-1 ml-auto text-right">
					<button (click)="createConditionFormGroup()" class="btn btn-primary" type="button"><fa-icon [icon]="faPlus" class="" [fixedWidth]="true"></fa-icon></button>
				</div>
				
			  </div>
	
	
		    </fieldset>
	    </div>
	
	    <div class="card mb-4">
		    <fieldset class="card-body">
			 
			<h5 class="card-title">3. Notify</h5>
			<!--<p class="text-muted">Reassuring text that explains what this is and what you do.</p>-->
	
			  <div class="form-group row mb-0">
	
			    <div class="col-sm-3">
			      <label>Message type</label>
			    </div>
		
				<div class="col-sm-5">
			      <label>Destination</label> 
				</div>
	
				<div class="col-sm-1 offset-sm-3">
				</div>
				
			  </div>
	
			  <div class="form-group row" formArrayName="notifications" *ngFor="let notification of myForm.controls.notifications?.controls; let i = index">

			    <ng-container [formGroupName]="i">

				    <div class="col-sm-3">
				      <select class="form-control" formControlName="Type">
				        <option value="Email">Email</option>
				        <option value="TextMessage">Text Message</option>
				      </select>
				    </div>
		
					<div class="col-sm-5">
				      <input type="text" class="form-control" placeholder="notifications@pulsarportal.com" formControlName="Destination"> 
					</div>
		
					<div class="col-sm-1 offset-sm-3 text-right">
						<button (click)="deleteNotificationFormGroup(i)" class="btn btn-danger" type="button"><fa-icon [icon]="faTrash" class="" [fixedWidth]="true"></fa-icon></button>
					</div>

			    </ng-container>
				
			  </div>
	
			  <div class="form-group row">
			    
				<div class="col-sm-1 ml-auto text-right">
					<button (click)="createNotificationFormGroup()" class="btn btn-primary" type="button"><fa-icon [icon]="faPlus" class="" [fixedWidth]="true"></fa-icon></button>
				</div>
				
			  </div>
	
	
		    </fieldset>
	    </div>
	
	
		<div class="my-3 text-right">
		    <button class="btn btn-success" type="submit" *ngIf="profileID == 'new'">
		    	<fa-icon [icon]="faSync" class="mr-1" [ngClass]="{'d-none' : !isUpdating}" [spin]="true"></fa-icon> 
		    	<fa-icon [icon]="faCheck" class="mr-1" [ngClass]="{'d-none' : isUpdating}"></fa-icon> 
				Create Profile
		    </button>
		    <button class="btn btn-success" type="submit" *ngIf="profileID != 'new'">
		    	<fa-icon [icon]="faSync" class="mr-1" [ngClass]="{'d-none' : !isUpdating}" [spin]="true"></fa-icon> 
		    	<fa-icon [icon]="faCheck" class="mr-1" [ngClass]="{'d-none' : isUpdating}"></fa-icon> 
		    	Update Profile
		    </button>
		</div>
	
	
	    </form>

	</div>
	
	<ng-template #loading>
		<div class="loading-spinner"></div>
	</ng-template>