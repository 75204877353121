import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppStateService {

  isSidebarVisible: boolean = false;

  sidebarVisibilityChange: Subject<boolean> = new Subject<boolean>();

  User = {};
  IsCollapsed = {};
  Account = {};
  
  AccountNumber = null; 

  private AccountSubject = new BehaviorSubject<any>(null);

  constructor()  {
	  
    this.sidebarVisibilityChange.subscribe((value) => {
        this.isSidebarVisible = value
    });
    
    this.IsCollapsed = this.getIsCollapsedSession();

    this.AccountNumber = this.getCurrentAccountNumberSession();
    
  }

  hideSidebar() {
    this.sidebarVisibilityChange.next(false);
  }
  
  showSidebar() {
    this.sidebarVisibilityChange.next(true);
  }
  
  setUser(User) {
    this.User = User;
  }

  getUser() {
    return this.User;
  }

  setAccount(Account) {
  	this.AccountSubject.next(Account);
  }

  getAccount(): Observable<any> {
    return this.AccountSubject.asObservable();
  }

  getIsCollapsed() {
    return this.IsCollapsed;
  }
 
  setIsCollapsed(IsCollapsed) {
    this.IsCollapsed = IsCollapsed;
    this.setIsCollapsedSession(IsCollapsed);
  }

  getCurrentAccountNumber() {
    return this.AccountNumber;
  }
 
  setCurrentAccountNumber(AccountNumber) {
    this.AccountNumber = AccountNumber;
    this.setCurrentAccountNumberSession(AccountNumber);
  }
  
  /* Session Functions */ 
  
  getIsCollapsedSession() {
	var Storage = JSON.parse(sessionStorage.getItem('IsCollapsed'));  
	if (Storage) return Storage;
	else return {};
  }

  setIsCollapsedSession(IsCollapsed) {
    sessionStorage.setItem('IsCollapsed', JSON.stringify(IsCollapsed));
  }

  getCurrentAccountNumberSession() {
	var Account = JSON.parse(sessionStorage.getItem('AccountNumber'));  
	if (Account) return Account;
	else return false;
  }

  setCurrentAccountNumberSession(Account) {
	console.log(Account);
	sessionStorage.setItem('AccountNumber', Account);
  }


}
