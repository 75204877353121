import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';

import { faPlus, faEdit, faCopy, faTrash, faSatelliteDish } from '@fortawesome/pro-regular-svg-icons';

import { AppStateService } from './../app-state.service';
import { Pulsar4Service } from '../pulsar-4.service';

import { SmartTable, of, TableState } from 'smart-table-ng';
import { DefaultSettingsService } from './default-settings.service';
import server from 'smart-table-server';

import { trigger, state, style, animate, transition } from '@angular/animations';

import { Subscription } from 'rxjs';

import { ProfileDeleteModalComponent } from '../profile-delete-modal/profile-delete-modal.component'

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

const providers = [{
  provide: SmartTable,
  useFactory: (Pulsar: Pulsar4Service, settings: TableState) => of([], settings, server({
    query: (tableState) => Pulsar.getBarringProfilesTable(tableState)
  })),
  deps: [Pulsar4Service, DefaultSettingsService]
}];

@Component({
  selector: 'app-bars',
  templateUrl: './bars.component.html',
  styleUrls: ['./bars.component.scss'],
  providers,
  animations: [
    trigger('fade', [      
      transition('void => *', [
        style({opacity: 0}),
        animate(200, style({opacity: 1}))
      ]),
      transition('* => void', [
        animate(200, style({opacity: 0}))
      ])
    ])
  ]
})
export class BarsComponent implements OnInit {

  faPlus = faPlus;
  faEdit = faEdit;
  faCopy = faCopy; 
  faTrash = faTrash;
  faSatelliteDish = faSatelliteDish;

  Profiles: any = [];
  public isLoading = true; 
  public showCard = false; 

  selectedRow : Number = null;
  ActiveProfile: any = [];

  subscription: Subscription;

  @ViewChild('table', { static: false }) table;

  selectedAccountNumber = this.appStateService.getCurrentAccountNumber();

  constructor(private changeDetectorRef: ChangeDetectorRef, private appStateService: AppStateService, private modalService: NgbModal, private pulsarService: Pulsar4Service) {
  }

  ngOnInit() {
    setTimeout(() => {
  	this.appStateService.showSidebar();
      }, 
    0);

	this.subscription = this.appStateService.getAccount().subscribe(data => { 
		this.isLoading = false;
		this.changeDetectorRef.detectChanges();
		if (data && this.selectedAccountNumber != data.accountNum) {
			this.selectedAccountNumber = data.accountNum;
			this.table.table.exec();
		}
    });
  }

  RowSelected(i, u, e) {
	  if (this.selectedRow == i) {
		  this.selectedRow = null;
		  this.ActiveProfile = [];	
		  this.showCard = false;	  
	  } else {
		  this.selectedRow = i;
		  this.ActiveProfile = u;
		  this.showCard = true;

		  setTimeout(() => {
			  window.scrollTo({
			    top: e.offsetTop - 50, //add your necessary value
			    behavior: "smooth"  //Smooth transition to roll
			  });
		  });

	  }
  }

  deleteProfile(profile) {
    const modalRef = this.modalService.open(ProfileDeleteModalComponent);
	modalRef.componentInstance.name = profile.name;
	
	modalRef.result.then((result) => {
	  this.pulsarService.deleteAlertingProfile(profile.id).subscribe(Service => {
		  this.showCard = false;	  
		  this.selectedRow = null;
		  this.ActiveProfile = [];	
		  this.table.table.exec();
	  });
    }, (reason) => {
      //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

}
