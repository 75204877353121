<div class="card shadow list-preview" @fade *ngIf="showCard">
	
  <div class="card-body">

  	<div *ngIf="!isLoading; else loading">
	
		<div class="d-flex justify-content-between">  
	    	<h5 class="card-title mb-0">{{Service.displayName ? Service.displayName : Service.serviceNum}}</h5>
	    	<div class="text-right">
				<a [routerLink]="['/service',Service.serviceID]" class="btn btn-sm btn-primary mr-2 mb-2"><fa-icon [icon]="faEye" class="mr-1"></fa-icon> View</a>
	    	</div>
		</div>
	
		<dl class="row mb-0">
		  <dt class="col-sm-3 text-right" *ngIf="Attributes.Description">Notes</dt>
		  <dd class="col-sm-9" *ngIf="Attributes.Description">{{Attributes.Description}}</dd>
		  <dt class="col-sm-3 text-right" *ngIf="Attributes.Tags.length > 0">Tags</dt>
		  <dd class="col-sm-9" *ngIf="Attributes.Tags.length > 0">
		  	<span class="pulsar-tag mr-1" [ngStyle]="{'background-color': Tag.hex}" *ngFor="let Tag of Attributes.Tags">{{Tag.display}}</span>
		  </dd>
		</dl>
		<hr>
		<dl class="row mb-0">
		  <dt class="col-sm-3 text-right" *ngIf="ServiceDetail.vesselName">Vessel Name</dt>
		  <dd class="col-sm-9" *ngIf="ServiceDetail.vesselName">{{ServiceDetail.vesselName}}</dd>
		  <dt class="col-sm-3 text-right">Service ID</dt>
		  <dd class="col-sm-9">{{ServiceDetail.serviceID}}</dd>
		  <dt class="col-sm-3 text-right">MSISDN</dt>
		  <dd class="col-sm-9">{{ServiceDetail.msisdn}}</dd>
		  <dt class="col-sm-3 text-right" *ngIf="ServiceDetail.sim">ICCID</dt>
		  <dd class="col-sm-9" *ngIf="ServiceDetail.sim">{{Service.sim}}</dd>
		  <dt class="col-sm-3 text-right" *ngIf="ServiceDetail.ipAddressList.length > 0">IP Addresses</dt>
		  <dd class="col-sm-9" *ngIf="ServiceDetail.ipAddressList.length > 0">
		  	<span *ngFor="let ipAddressList of ServiceDetail.ipAddressList; let i = index">{{i ? ', ': ''}}{{ipAddressList.ipAddress}}</span>
		  </dd>
		</dl>
		<hr>
		<dl class="row mb-0">
		  <dt class="col-sm-3 text-right" *ngIf="ServiceDetail.packageInfo">Package</dt>
		  <dd class="col-sm-9" *ngIf="ServiceDetail.packageInfo">{{ServiceDetail.packageInfo.note}}</dd>
		  <dt class="col-sm-3 text-right">Connected</dt>
		  <dd class="col-sm-9">{{ServiceDetail.connectionDateTime | date: 'long'}}</dd>
		  <dt class="col-sm-3 text-right">Plan Started</dt>
		  <dd class="col-sm-9">{{ServiceDetail.planStartTime | date: 'long'}}</dd>
		  <dt class="col-sm-3 text-right">Billing Cycle</dt>
		  <dd class="col-sm-9">{{moment.localeData().ordinal(ServiceDetail.billingCycleDay)}} of the Month</dd>
		</dl>
	
	  </div>
  </div>
  
  <ng-template #loading>
  	<div class="loading-spinner"></div>
  </ng-template>
  
</div>
