import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private oauthService: OAuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    let canActivate: boolean;
    let claims = this.oauthService.getIdentityClaims();

	const permission = route.data["permission"];

    if (this.oauthService.hasValidIdToken()) {
		canActivate = claims['permissions'].includes(permission.require);
    }

    if (!canActivate) this.router.navigate(['/home']);
        
    return canActivate;
  }
}
