	<div class="mt-1 mb-3">
		<a routerLink="/service" class="font-weight-bold"><fa-icon [icon]="faChevronLeft"></fa-icon> Services</a>
	</div>

  	<div *ngIf="!isLoading; else loading">

		<div class="row">
			
			<div class="col">
	
				<div class="row">
				
					<div class="col">
						<h3 class="mb-3">
							
							<span *ngIf="!IsEditing['displayName']" @fadeField>
								{{ServiceDetail.displayName ? ServiceDetail.displayName : ServiceDetail.serviceNum}} 
								<a [routerLink]="" (click)="editField('displayName')" style="font-size: 50%">
									<fa-icon [icon]="faEdit"></fa-icon>
								</a>
							</span>
							
							<span *ngIf="IsEditing['displayName']" @fadeField>
								<form class="form-inline" (ngSubmit)="updateField('displayName')">
								  <label class="sr-only" for="inlineFormInputName2">Name</label>
								  <input type="text" class="form-control mb-2 mr-sm-2" [(ngModel)]="ServiceDetail.displayName" placeholder="Dispay Name" name="displayName">
								  <button (click)="hideField('displayName')" type="button" class="btn btn-danger mb-2 mr-sm-2">
								  	<fa-icon [icon]="faTimes"></fa-icon>
								  </button>
								  <button type="submit" class="btn btn-success mb-2">
									<fa-icon [icon]="faSync" class="mr-1" [ngClass]="{'d-none' : !isUpdating}" [spin]="true"></fa-icon> 
									<fa-icon [icon]="faCheck" class="mr-1" [ngClass]="{'d-none' : isUpdating}"></fa-icon> 
								  </button>
								</form>
							</span>
							
							<span class="d-block">
								<small [ngClass]="{'text-success': ServiceDetail.networkStatus == 'online', 'text-danger': ServiceDetail.networkStatus == 'offline'}" style="font-size: 60%">
								<fa-icon [icon]="faWifi" *ngIf="ServiceDetail.networkStatus == 'online'"></fa-icon> <fa-icon [icon]="faWifiSlash" *ngIf="ServiceDetail.networkStatus == 'offline'"></fa-icon> {{ServiceDetail.networkStatus | titlecase}}</small> 
								<small style="font-size: 60%"> - </small> <small class="text-muted" style="font-size: 60%">{{ServiceDetail.msisdn}}</small>
							</span>
						</h3>
							
						<div class="mb-3">
							
						    <button (click)="toggleBar()" class="btn btn-sm btn-danger mr-4" *ngIf="ServiceDetail.dataStatus == 'Unbarred'">
								<fa-icon [icon]="faSync" class="mr-1" [ngClass]="{'d-none' : !isUpdatingBar}" [spin]="true"></fa-icon> 
						    	<fa-icon [icon]="faLock" class="mr-1" [ngClass]="{'d-none' : isUpdatingBar}"></fa-icon> Bar Service
						    </button>
						    <button (click)="toggleBar()" class="btn btn-sm btn-success mr-4" *ngIf="ServiceDetail.dataStatus == 'Barred'">
								<fa-icon [icon]="faSync" class="mr-1" [ngClass]="{'d-none' : !isUpdatingBar}" [spin]="true"></fa-icon> 
						    	<fa-icon [icon]="faLockOpen" class="mr-1" [ngClass]="{'d-none' : isUpdatingBar}"></fa-icon> Unbar Service
						    </button>

						    <button (click)="toggleBar()" class="btn btn-sm btn-secondary mr-4" *ngIf="ServiceDetail.dataStatus == 'Pending DATABAR' || ServiceDetail.dataStatus == 'Pending GROUPDATABAR'" disabled="disabled">
						    	<fa-icon [icon]="faLock" class="mr-1"></fa-icon> Bar Pending
						    </button>
						    <button (click)="toggleBar()" class="btn btn-sm btn-secondary mr-4" *ngIf="ServiceDetail.dataStatus == 'Pending DATAUNBAR' || ServiceDetail.dataStatus == 'Pending GROUPDATAUNBAR'" disabled="disabled">
						    	<fa-icon [icon]="faLockOpen" class="mr-1"></fa-icon> Unbar Pending
						    </button>

						    <!--<button (click)="summaryUsage(content)" class="btn btn-sm btn-secondary mr-2"><fa-icon [icon]="faChartBar" class="mr-1"></fa-icon> Summary Usage</button>-->
						    <!--<a [routerLink]="" class="btn btn-sm btn-secondary mr-2"><fa-icon [icon]="faStream" class="mr-1"></fa-icon> Data Detail</a>-->				
						    <!--<a [routerLink]="" class="btn btn-sm btn-secondary mr-2"><fa-icon [icon]="faChartLine" class="mr-1"></fa-icon> Statistics</a>-->				
						    <!--<a [routerLink]="" class="btn btn-sm btn-secondary"><fa-icon [icon]="faClipboard" class="mr-1"></fa-icon> Activity Log</a>-->				

						</div>
					</div>
		
				</div>
	
				<div class="card mb-3">
				  <div class="card-body">
					
					<div class="d-flex justify-content-between">  
				    	<h5 class="card-title mb-0">Notes</h5>
				    	<a (click)="ToggleCollapse('Notes')" [routerLink]="">
					    	<fa-icon [icon]="faChevronCircleUp" *ngIf="!IsCollapsed['Notes']"></fa-icon>
					    	<fa-icon [icon]="faChevronCircleDown" *ngIf="IsCollapsed['Notes']"></fa-icon>
					    </a>	
					</div>
	
					<div [ngbCollapse]="IsCollapsed['Notes']" class="mt-3">
						
						<p>
							<span *ngIf="!IsEditing['Description']" @fadeField>
								{{Attributes.Description}}
								<a [routerLink]="" (click)="editField('Description')" style="font-size: 80%" class="ml-1"><fa-icon [icon]="faEdit"></fa-icon></a>
							</span>

							<span *ngIf="IsEditing['Description']" @fadeField>
								<form (ngSubmit)="updateField('Description')">
								  <label class="sr-only">Description</label>
								  <input type="text" class="form-control mb-2 mr-sm-2" [(ngModel)]="Attributes.Description" placeholder="Description" name="displayName">
								  <button (click)="hideField('Description')" class="btn btn-danger mb-2 mr-sm-2" type="button">
								  	<fa-icon [icon]="faTimes"></fa-icon>
								  </button>
								  <button class="btn btn-success mb-2" type="submit">
									<fa-icon [icon]="faSync" class="mr-1" [ngClass]="{'d-none' : !isUpdating}" [spin]="true"></fa-icon> 
								  	<fa-icon [icon]="faCheck" [ngClass]="{'d-none' : isUpdating}"></fa-icon>
								  </button>
								</form>
							</span>

						</p>
	
						<p class="mb-0">
							<strong class="mr-2">Tags:</strong> 
								<span *ngIf="!IsEditing['Tags']" @fadeField>
									<span class="pulsar-tag mr-1" [ngStyle]="{'background-color': Tag.hex}" *ngFor="let Tag of Attributes.Tags">{{Tag.display}}</span>
									<a [routerLink]="" (click)="editField('Tags')" style="font-size: 80%" class="ml-1"><fa-icon [icon]="faEdit"></fa-icon></a>
								</span>
						
								<span *ngIf="IsEditing['Tags']" @fadeField>
								  <form (ngSubmit)="updateField('Tags')">
									<tag-input [(ngModel)]="Attributes.Tags"
									           [onlyFromAutocomplete]="true" class="mb-2" name="Tags">
									    <tag-input-dropdown [showDropdownIfEmpty]="true"
									                        [autocompleteObservable]='autocompleteTags'>
									    </tag-input-dropdown>
									</tag-input>

									<button (click)="hideField('Tags')" class="btn btn-danger mb-2 mr-sm-2" type="button">
									  <fa-icon [icon]="faTimes"></fa-icon>
									</button>
									<button class="btn btn-success mb-2" type="submit">
									  <fa-icon [icon]="faSync" class="mr-1" [ngClass]="{'d-none' : !isUpdating}" [spin]="true"></fa-icon> 
									  <fa-icon [icon]="faCheck" [ngClass]="{'d-none' : isUpdating}"></fa-icon>
									</button>
								  </form>
								</span>
						</p>
	
					</div>
	
				  </div>
				</div>
	
	
				<div class="card mb-3" *ngIf="ServiceDetail.dataUsage">
				  <div class="card-body">
					
					<div class="d-flex justify-content-between">  
				    	<h5 class="card-title mb-0">Activity This Month</h5>
				    	<a (click)="ToggleCollapse('Activity')" [routerLink]=""><fa-icon [icon]="faChevronCircleUp"></fa-icon></a>	
					</div>
	
					<div [ngbCollapse]="IsCollapsed['Activity']" class="mt-3">
						<dl class="row mb-0">
						  <dt class="col-sm-3 text-right" *ngIf="ServiceDetail.networkStatusUpdateTime">Last Online</dt>
						  <dd class="col-sm-9" *ngIf="ServiceDetail.networkStatusUpdateTime">{{ServiceDetail.networkStatusUpdateTime | date: 'long'}}</dd>
						  <dt class="col-sm-3 text-right">Billing Period Ends</dt>
						  
						  <dd class="col-sm-9">{{ServiceDetail.nextBillCycle | date: 'long'}}<br>
						  <strong *ngIf="moment(ServiceDetail.nextBillCycle).diff(moment(), 'days') > 0; else lessthan1">{{moment(ServiceDetail.nextBillCycle).diff(moment(), 'days')}}</strong><ng-template #lessthan1><strong>Less than 1</strong></ng-template> day(s) remaining</dd>
						  
						  

						  <dt class="col-sm-3 text-right">Total Data Allowance</dt>
						  <dd class="col-sm-9">{{ServiceDetail.dataUsage.dataIncluded | bytes}}</dd>
						  
						  <dt class="col-sm-3 text-right">Plan Usage</dt>
						  <dd class="col-sm-9">					  
	
							<div class="progress progress-data mt-1">
							  <div class="progress-bar bg-primary" role="progressbar" [ngStyle]="{'width': (ServiceDetail.dataUsage.percentage.inbound * 100) + '%'}">
								  {{(ServiceDetail.dataUsage.percentage.inbound * 100) + '%'}}
							  </div>
							  <div class="progress-bar bg-info" role="progressbar" [ngStyle]="{'width': (ServiceDetail.dataUsage.percentage.outbound * 100) + '%'}">
								  {{(ServiceDetail.dataUsage.percentage.outbound * 100) + '%'}}
							  </div>
							</div>
	
							<div class="progress progress-time">
							  <div class="progress-bar bg-secondary" role="progressbar" [ngStyle]="{'width': (ServiceDetail.dataUsage.percentage.elasped * 100) + '%'}"></div>
							</div>
	
	
						  </dd>
						  <dd class="col-sm-9 offset-sm-3">
							<div class="row">
								<div class="col"><span class="badge badge-pill badge-primary">Incoming</span> {{ServiceDetail.dataUsage.incoming | bytes}}</div>
								<div class="col"><span class="badge badge-pill badge-info">Outgoing</span> {{ServiceDetail.dataUsage.outgoing | bytes}}</div>
								<div class="col"><span class="badge badge-pill badge-secondary">Total</span> {{ServiceDetail.dataUsage.total | bytes}}</div>
							</div>
						</dl>
	
					</div>
			  	  </div>
				</div>
	
				<!--<div class="card mb-3">
				  <div class="card-body">
					
					<div class="d-flex justify-content-between">  
				    	<h5 class="card-title mb-0">Barring Profile</h5>
				    	<a (click)="ToggleCollapse('BarringProfile')" [routerLink]="">
					    	<fa-icon [icon]="faChevronCircleUp" *ngIf="!IsCollapsed['BarringProfile']"></fa-icon>
					    	<fa-icon [icon]="faChevronCircleDown" *ngIf="IsCollapsed['BarringProfile']"></fa-icon>
					    </a>	
					</div>
	
					<div [ngbCollapse]="IsCollapsed['BarringProfile']" class="mt-3" #barringProfile>
	
	
						<div class="row">
						    <div class="col text-center">
							    
							    <h6>Data Usage Period</h6>
						    				  
						        <div class="progress-round mx-auto mb-3" data-value='75'>
						          <span class="progress-left">
						                        <span class="progress-bar border-success"></span>
						          </span>
						          <span class="progress-right">
						                        <span class="progress-bar border-success"></span>
						          </span>
						          <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
						            <div>
							            <span class="h2 font-weight-bold">
							            	75%
							            </span>
						            	<p class="mt-1">Used of 2GB</p>
						            </div>
						          </div>
						        </div>
	
								<p class="text-center"><span class="badge badge-pill badge-secondary">Total Used</span> 0.5 MB <span class="ml-2 badge badge-pill badge-success">Total Remaining</span> 1.5 MB</p>
	
								<p><fa-icon [icon]="faCalendarTimes" class="mr-1"></fa-icon> 15 May 2021 at 07:00:00 -07:00 (<strong>3 days remaining</strong>)</p>
								
								<div class="btn-group" ngbDropdown>
								  <button type="button" class="btn btn-sm btn-secondary dropdown-toggle" ngbDropdownToggle>
								    <fa-icon [icon]="faPlusCircle" class="mr-1"></fa-icon> Add Additional Data
								  </button>
								  <div ngbDropdownMenu>
								    <a class="dropdown-item" href="#">Add 50 MB</a>
								    <a class="dropdown-item" href="#">Add 100 MB</a>
								    <a class="dropdown-item" href="#">Add 1 GB</a>
								  </div>
								</div>
	
						    </div>
	
						    <div class="col text-center">
	
							    <h6>Billing Cycle</h6>
						    				  
						        <div class="progress-round mx-auto mb-3" data-value='25'>
						          <span class="progress-left">
						                        <span class="progress-bar border-primary"></span>
						          </span>
						          <span class="progress-right">
						                        <span class="progress-bar border-primary"></span>
						          </span>
						          <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
						            <div>
							            <span class="h2 font-weight-bold">
							            	25%
							            </span>
						            	<p class="mt-1">Used of 2GB</p>
						            </div>
						          </div>
						        </div>
	
								<p><span class="badge badge-pill badge-secondary">Total Used</span> 0.5 MB <span class="ml-2 badge badge-pill badge-primary">Total Remaining</span> 1.5 MB</p>
						        
								<p><fa-icon [icon]="faCalendarTimes" class="mr-1"></fa-icon> 15 May 2021 at 07:00:00 -07:00 (<strong>3 days remaining</strong>)</p>
								
						    </div>
	
	
						</div>
						
					</div>
	
				  </div>
				</div>-->
				
				<div class="card mb-3">
				  <div class="card-body">
					
					<div class="d-flex justify-content-between">  
				    	<h5 class="card-title mb-0">Summary</h5>
				    	<a (click)="ToggleCollapse('Summary')" [routerLink]="">	
					    	<fa-icon [icon]="faChevronCircleUp" *ngIf="!IsCollapsed['Summary']"></fa-icon>
					    	<fa-icon [icon]="faChevronCircleDown" *ngIf="IsCollapsed['Summary']"></fa-icon>
						</a>
					</div>
	
					<div [ngbCollapse]="IsCollapsed['Summary']" class="mt-3">
						<dl class="row mb-0">
						  <dt class="col-sm-3 text-right" *ngIf="ServiceDetail.vesselName">Vessel Name</dt>
						  <dd class="col-sm-9" *ngIf="ServiceDetail.vesselName">{{ServiceDetail.vesselName}}</dd>
						  <dt class="col-sm-3 text-right">Service ID</dt>
						  <dd class="col-sm-9">{{ServiceDetail.serviceID}}</dd>
						  <dt class="col-sm-3 text-right">MSISDN</dt>
						  <dd class="col-sm-9">{{ServiceDetail.msisdn}}</dd>
						  <dt class="col-sm-3 text-right" *ngIf="ServiceDetail.sim">ICCID</dt>
						  <dd class="col-sm-9" *ngIf="ServiceDetail.sim">{{ServiceDetail.sim}}</dd>
						  <dt class="col-sm-3 text-right" *ngIf="ServiceDetail.ipAddressList.length > 0">IP Addresses</dt>
						  <dd class="col-sm-9" *ngIf="ServiceDetail.ipAddressList.length > 0">
						  	<span *ngFor="let ipAddressList of ServiceDetail.ipAddressList; let i = index">{{i ? ', ': ''}}{{ipAddressList.ipAddress}}</span>
						  	<a [routerLink]="" (click)="ScrollTo(IPAddresses)" class="ml-1" style="font-size: 80%"><fa-icon [icon]="faEye"></fa-icon></a>
						  </dd>
						</dl>
						<hr>
						<dl class="row mb-0">
						  <dt class="col-sm-3 text-right" *ngIf="ServiceDetail.packageInfo">Package</dt>
						  <dd class="col-sm-9" *ngIf="ServiceDetail.packageInfo">{{ServiceDetail.packageInfo.note}}</dd>
						  <dt class="col-sm-3 text-right">Connected</dt>
						  <dd class="col-sm-9">{{ServiceDetail.connectionDateTime | date: 'long'}}</dd>
						  <dt class="col-sm-3 text-right">Plan Started</dt>
						  <dd class="col-sm-9">{{ServiceDetail.planStartTime | date: 'long'}}</dd>
						  <dt class="col-sm-3 text-right">Billing Cycle</dt>
						  <dd class="col-sm-9">{{moment.localeData().ordinal(ServiceDetail.billingCycleDay)}} of the Month</dd>
						</dl>
					</div>
	
				  </div>
				</div>
	
				<div class="card mb-3">
				  <div class="card-body">
					
					<div class="d-flex justify-content-between">  
				    	<h5 class="card-title mb-0">Groups &amp; Profiles</h5>
				    	<a (click)="ToggleCollapse('Groups')" [routerLink]="">
					    	<fa-icon [icon]="faChevronCircleUp" *ngIf="!IsCollapsed['Groups']"></fa-icon>
					    	<fa-icon [icon]="faChevronCircleDown" *ngIf="IsCollapsed['Groups']"></fa-icon>
					    </a>	
					</div>
	
					<div [ngbCollapse]="IsCollapsed['Groups']" class="mt-3">
						<dl class="row mb-0">
							
						  <!--<dt class="col-sm-3 text-right">Template</dt>
						  <dd class="col-sm-9">Test Group</dd>-->

						  <dt class="col-sm-3 text-right" *ngIf="ServiceDetail.groupServiceID">Data Group</dt>
						  <dd class="col-sm-9" *ngIf="ServiceDetail.groupServiceID">

						  	<a [routerLink]="['/service',ServiceDetail.groupServiceID]">
							  	<fa-icon [icon]="faSatelliteDish" [fixedWidth]="true"></fa-icon> {{ServiceDetail.groupService.label}}
							</a> 

						  </dd>

						  <dt class="col-sm-3 text-right">Alert Profiles</dt>
						  <dd class="col-sm-9">
						  
						  	<span *ngIf="!IsEditing['AlertProfile']" @fadeField>	
						  		<span *ngIf="DataUsage.AlertingProfiles.length < 1"><fa-icon [icon]="faBells" [fixedWidth]="true"></fa-icon> Default Profile</span>
						  		<a [routerLink]="['/alert-profile',profile.value]" *ngFor="let profile of DataUsage.AlertingProfiles"><fa-icon [icon]="faBells" [fixedWidth]="true"></fa-icon> {{profile.display}}</a> 
						  		<a [routerLink]="" (click)="editField('AlertProfile')" style="font-size: 80%" class="ml-1"><fa-icon [icon]="faEdit"></fa-icon></a>
						  	</span>

							<span *ngIf="IsEditing['AlertProfile']" @fadeField>
							  <form (ngSubmit)="updateField('AlertProfile')">
								<tag-input [(ngModel)]="DataUsage.AlertingProfiles"
								           [onlyFromAutocomplete]="true" class="mb-2" name="AlertProfile" secondaryPlaceholder="Select Alerting Profiles">
								    <tag-input-dropdown [showDropdownIfEmpty]="true"
								                        [autocompleteObservable]='autocompleteAlertingProfiles'>
								    </tag-input-dropdown>
								</tag-input>

								<button (click)="hideField('AlertProfile')" class="btn btn-danger mb-2 mr-sm-2" type="button">
								  <fa-icon [icon]="faTimes"></fa-icon>
								</button>
								<button class="btn btn-success mb-2" type="submit">
								  <fa-icon [icon]="faSync" class="mr-1" [ngClass]="{'d-none' : !isUpdating}" [spin]="true"></fa-icon> 
								  <fa-icon [icon]="faCheck" [ngClass]="{'d-none' : isUpdating}"></fa-icon>
								</button>
							  </form>
							</span>

						  </dd>
						  <dt class="col-sm-3 text-right">Barring Profile</dt>
						  <dd class="col-sm-9">
						  
						  	<span *ngIf="!IsEditing['BarProfile']" @fadeField>
						  		<span *ngIf="DataUsage.BarringProfiles.length < 1"><fa-icon [icon]="faLock" [fixedWidth]="true"></fa-icon> Default Profile</span>
						  		<a [routerLink]="['/bar-profile',profile.value]" *ngFor="let profile of DataUsage.BarringProfiles"><fa-icon [icon]="faLock" [fixedWidth]="true"></fa-icon> {{profile.display}}</a> 
						  		<a [routerLink]="" (click)="editField('BarProfile')" style="font-size: 80%" class="ml-1"><fa-icon [icon]="faEdit"></fa-icon></a>
						  	</span>

							<span *ngIf="IsEditing['BarProfile']" @fadeField>
							  <form (ngSubmit)="updateField('BarProfile')">
								<tag-input [(ngModel)]="DataUsage.BarringProfiles"
								           [onlyFromAutocomplete]="true" class="mb-2" name="BarProfile" secondaryPlaceholder="Select Barring Profile" maxItems="1">
								    <tag-input-dropdown [showDropdownIfEmpty]="true"
								                        [autocompleteObservable]='autocompleteBarringProfiles'>
								    </tag-input-dropdown>
								</tag-input>

								<button (click)="hideField('BarProfile')" class="btn btn-danger mb-2 mr-sm-2" type="button">
								  <fa-icon [icon]="faTimes"></fa-icon>
								</button>
								<button class="btn btn-success mb-2" type="submit">
								  <fa-icon [icon]="faSync" class="mr-1" [ngClass]="{'d-none' : !isUpdating}" [spin]="true"></fa-icon> 
								  <fa-icon [icon]="faCheck" [ngClass]="{'d-none' : isUpdating}"></fa-icon>
								</button>
							  </form>
							</span>

						  </dd>
						  <dt class="col-sm-3 text-right">Firewall Profile</dt>
						  <dd class="col-sm-9">
						  
						  	<span *ngIf="!IsEditing['FirewallProfile']" @fadeField>						  
						  		<a [routerLink]="['/firewall-profile',profile.value]" *ngFor="let profile of Attributes.FirewallProfile"><fa-icon [icon]="faChessRook" [fixedWidth]="true"></fa-icon> {{profile.display}}</a> 
						  		<a [routerLink]="" (click)="editField('FirewallProfile')" style="font-size: 80%" class="ml-1"><fa-icon [icon]="faEdit"></fa-icon></a>
						  	</span>					
						  		
							<span *ngIf="IsEditing['FirewallProfile']" @fadeField>
							  <form (ngSubmit)="updateField('FirewallProfile')">
								<tag-input [(ngModel)]="Attributes.FirewallProfile"
								           [onlyFromAutocomplete]="true" class="mb-2" name="FirewallProfile" secondaryPlaceholder="Select Firewall Profile" maxItems="1">
								    <tag-input-dropdown [showDropdownIfEmpty]="true"
								                        [autocompleteObservable]='autocompleteFirewallProfiles'>
								    </tag-input-dropdown>
								</tag-input>

								<button (click)="hideField('FirewallProfile')" class="btn btn-danger mb-2 mr-sm-2" type="button">
								  <fa-icon [icon]="faTimes"></fa-icon>
								</button>
								<button class="btn btn-success mb-2" type="submit">
								  <fa-icon [icon]="faSync" class="mr-1" [ngClass]="{'d-none' : !isUpdating}" [spin]="true"></fa-icon> 
								  <fa-icon [icon]="faCheck" [ngClass]="{'d-none' : isUpdating}"></fa-icon>
								</button>
							  </form>
							</span>

						  </dd>
						  
						  <!--<dt class="col-sm-3 text-right">Domain Filter</dt>
						  <dd class="col-sm-9"><a routerLink="/domains"><fa-icon [icon]="faGlobe" [fixedWidth]="true"></fa-icon> Domain Filter</a></dd>-->
						</dl>
					</div>
	
				  </div>
				</div>
	
				<div class="card mb-3" [hidden]="DataGroup.length < 1">
				  <div class="card-body">
					
					<div class="d-flex justify-content-between">  
				    	<h5 class="card-title mb-0">Data Group</h5>
				    	<a (click)="ToggleCollapse('DataPool')" [routerLink]="">
					    	<fa-icon [icon]="faChevronCircleUp" *ngIf="!IsCollapsed['DataPool']"></fa-icon>
					    	<fa-icon [icon]="faChevronCircleDown" *ngIf="IsCollapsed['DataPool']"></fa-icon>
					    </a>	
					</div>
	
					<div [ngbCollapse]="IsCollapsed['DataPool']" class="mt-3">
	
					<table class="table">
					  <thead>
					    <tr>
					      <th scope="col">Name</th>
					      <th scope="col">Type</th>
					      <th scope="col">MSISDN</th>
					      <th scope="col">ICCID</th>
						  <th scope="col" class="text-center" colspan="2">Status</th>
					    </tr>
					  </thead>
					  <tbody>
					    <tr *ngFor="let ServicePool of DataGroup">
					      <td>
						  	<a [routerLink]="['/service',ServicePool.serviceID]">
						  		<fa-icon [icon]="faSatelliteDish" [fixedWidth]="true"></fa-icon> {{ServicePool.displayName ? ServicePool.displayName : ServicePool.serviceNum}} 
						  	</a>
					      </td>
					      <td>{{ServicePool.serviceType}}</td>
					      <td>{{ServicePool.msisdn}}</td>
					      <td>{{ServicePool.sim}}</td>
					      <td class="align-middle text-center">
						      <span class="text-success" *ngIf="ServicePool.networkStatus == 'online'"><fa-icon [icon]="faWifi" [fixedWidth]="true"></fa-icon></span>
						      <span class="text-danger" *ngIf="ServicePool.networkStatus == 'offline'"><fa-icon [icon]="faWifiSlash" [fixedWidth]="true"></fa-icon></span>
						  </td>
					      <td class="align-middle text-center">
						      <span class="text-success" *ngIf="ServicePool.dataStatus == 'Unbarred'"><fa-icon [icon]="faLockOpen" [fixedWidth]="true"></fa-icon></span>
						      <span class="text-danger" *ngIf="ServicePool.dataStatus != 'Unbarred'"><fa-icon [icon]="faLock" [fixedWidth]="true"></fa-icon></span>
						  </td>
					    </tr>
					  </tbody>
					</table>
	
					</div>
	
				  </div>
				</div>

				<div class="card mb-3" [hidden]="ServiceDetail.ipAddressList.length < 1" #IPAddresses>
				  <div class="card-body">
					
					<div class="d-flex justify-content-between">  
				    	<h5 class="card-title mb-0">IP Addresses</h5>
				    	<a (click)="ToggleCollapse('IPAddresses')" [routerLink]="">
					    	<fa-icon [icon]="faChevronCircleUp" *ngIf="!IsCollapsed['IPAddresses']"></fa-icon>
					    	<fa-icon [icon]="faChevronCircleDown" *ngIf="IsCollapsed['IPAddresses']"></fa-icon>
					    </a>	
					</div>
	
					<div [ngbCollapse]="IsCollapsed['IPAddresses']" class="mt-3">
	
					<table class="table">
					  <thead>
					    <tr>
					      <th scope="col">IP Address</th>
					      <th scope="col">APN</th>
					      <th scope="col">Service Number</th>
					      <th scope="col">Dynamic</th>
					      <th scope="col">Public</th>
					      <th scope="col">Pool</th>
					    </tr>
					  </thead>
					  <tbody>
					    <tr *ngFor="let ipAddressList of ServiceDetail.ipAddressList">
					      <td>{{ipAddressList.ipAddress}}</td>
					      <td>{{ipAddressList.apn}}</td>
					      <td>{{ipAddressList.msisdn}}</td>
					      <td>{{ipAddressList.isDynamic}}</td>
					      <td>{{ipAddressList.isPublic}}</td>
					      <td>{{ipAddressList.poolName}}</td>
					    </tr>
					  </tbody>
					</table>
	
					</div>
	
				  </div>
				</div>
	
				
			</div>
			
		</div>
	
	</div>
	
	<ng-template #loading>
		<div class="loading-spinner"></div>
	</ng-template>
	
	<ng-template #content let-modal>
	  <div class="modal-header">
	    <h4 class="modal-title" id="modal-basic-title">Summary Usage</h4>
	    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
	      <span aria-hidden="true">&times;</span>
	    </button>
	  </div>
	  <div class="modal-body">
	    <form>
		  <div class="form-row">
		    <div class="form-group col-md-4">
		      <label for="inputState">Summary Type</label>
		      <select id="inputState" class="form-control">
		        <option selected>Date Range</option>
		        <option>Billing Month</option>
		      </select>
		    </div>
		    <div class="form-group col-md-4">
		      <label for="inputZip">Start Date</label>
			    <div class="input-group">
			      <input class="form-control" placeholder="yyyy-mm-dd"
			             name="dp" [(ngModel)]="model" ngbDatepicker #d="ngbDatepicker">
			      <div class="input-group-append">
			        <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button"><fa-icon [icon]="faCalendarAlt"></fa-icon></button>
			      </div>
			    </div>
		    </div>
		    <div class="form-group col-md-4">
		      <label for="inputZip">End Date</label>
			    <div class="input-group">
			      <input class="form-control" placeholder="yyyy-mm-dd"
			             name="dp2" [(ngModel)]="model2" ngbDatepicker #d2="ngbDatepicker">
			      <div class="input-group-append">
			        <button class="btn btn-outline-secondary " (click)="d2.toggle()" type="button"><fa-icon [icon]="faCalendarAlt"></fa-icon></button>
			      </div>
			    </div>
		    </div>
		  </div>
	    </form>

		<div class="row">
			<div class="col"><span class="badge badge-pill badge-primary">Incoming</span> 0.75 MB</div>
			<div class="col"><span class="badge badge-pill badge-info">Outgoing</span> 1.5MB</div>
			<div class="col"><span class="badge badge-pill badge-secondary">Total</span> 2.25 MB</div>
		</div>

	    <google-chart [data]="myData" [type]="myType" style="width: 100%; height: 400px" [options]="{bar: { groupWidth:'90%'}, legend: { position: 'none' }, isStacked: true, colors: ['#0067b4', '#17a2b8'] }"></google-chart>
	    
	  </div>
	  <div class="modal-footer">
	    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
	  </div>
	</ng-template>

	<ng-template #profile let-modal>
	  <div class="modal-header">
	    <h4 class="modal-title" id="modal-basic-title">Profile</h4>
	    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
	      <span aria-hidden="true">&times;</span>
	    </button>
	  </div>
	  <div class="modal-body">
	    <form>

	    </form>

		<div class="row">
			<div class="col"><span class="badge badge-pill badge-primary">Incoming</span> 0.75 MB</div>
			<div class="col"><span class="badge badge-pill badge-info">Outgoing</span> 1.5MB</div>
			<div class="col"><span class="badge badge-pill badge-secondary">Total</span> 2.25 MB</div>
		</div>

	    <google-chart [data]="myData" [type]="myType" style="width: 100%; height: 400px" [options]="{bar: { groupWidth:'90%'}, legend: { position: 'none' }, isStacked: true, colors: ['#0067b4', '#17a2b8'] }"></google-chart>
	    
	  </div>
	  <div class="modal-footer">
	    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
	  </div>
	</ng-template>

