<div class="modal-header">
    <h4 class="modal-title" id="modal-title"><fa-icon [icon]="faQuestionCircle" class="mr-1" [fixedWidth]="true"></fa-icon> Delete Profile</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p><strong>Are you sure you want to delete <span class="text-primary">{{name}}</span>?</strong></p>
    <p><span class="text-danger">This operation can not be undone.</span></p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">Cancel</button>
    <button type="button" ngbAutofocus class="btn btn-danger" (click)="modal.close('Ok click')">Ok</button>
</div>