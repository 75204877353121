	<div class="mt-1 mb-3">
		<a routerLink="/template" class="font-weight-bold"><fa-icon [icon]="faChevronLeft"></fa-icon> Templates</a>
	</div>

	<div *ngIf="!isLoading; else loading">
		<div class="mb-4">
	    	<h3 *ngIf="templateID == 'new'">Create Template</h3>
	    	<h3 *ngIf="templateID != 'new'">Update Template</h3>
			<!--<p class="text-muted">Reassuring text that explains what this is and what you do.</p>-->
		</div>
	    
	    <form [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
	    
	    <div class="card mb-4">
		    <fieldset class="card-body">
			 
			<h5 class="card-title">Template Details</h5>
			<!--<p class="text-muted">Reassuring text that explains what this is and what you do.</p>-->
			    
			  <div class="form-group row">
			    <label for="inputEmail3" class="col-sm-2 col-form-label">Name</label>
			    <div class="col-sm-10">
			      <input type="text" class="form-control" placeholder="Template Name" formControlName="Name" required>
			    </div>
			  </div>

		    </fieldset>
	    </div>
		
		<div class="my-3 text-right">
		    <button class="btn btn-success" type="submit" *ngIf="templateID == 'new'">
		    	<fa-icon [icon]="faSync" class="mr-1" [ngClass]="{'d-none' : !isUpdating}" [spin]="true"></fa-icon> 
		    	<fa-icon [icon]="faCheck" class="mr-1" [ngClass]="{'d-none' : isUpdating}"></fa-icon> 
				Create Template
		    </button>
		    <button class="btn btn-success" type="submit" *ngIf="templateID != 'new'">
		    	<fa-icon [icon]="faSync" class="mr-1" [ngClass]="{'d-none' : !isUpdating}" [spin]="true"></fa-icon> 
		    	<fa-icon [icon]="faCheck" class="mr-1" [ngClass]="{'d-none' : isUpdating}"></fa-icon> 
		    	Update Template
		    </button>
		</div>

	    </form>

	</div>
	
	<ng-template #loading>
		<div class="loading-spinner"></div>
	</ng-template>