import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DefaultSettingsService {
  search = {};
  filter = {}
  sort = {
    pointer: 'label',
    direction: 'asc'
  }
  slice = {
    page: 1,
    size: 20
  };
}
