import { Component, OnInit } from '@angular/core';

import { AppStateService } from './../app-state.service';

import { faEdit, faChevronLeft, faPlus, faCheck, faTrash, faSync } from '@fortawesome/pro-regular-svg-icons';

import { CurrentTemplateService } from './current-template.service';

import { Pulsar4Service } from '../pulsar-4.service';

import { FormBuilder, FormGroup, FormArray } from '@angular/forms';

import { Router } from '@angular/router';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss']
})
export class TemplateComponent implements OnInit {

  faEdit = faEdit;
  faChevronLeft = faChevronLeft;
  faCheck = faCheck;
  faTrash = faTrash; 
  faPlus = faPlus;
  faSync = faSync;
  
  public isLoading = true;  
  public isUpdating = false;
  
  public templateID;

  constructor(private appStateService: AppStateService, private currentTemplateService: CurrentTemplateService, private pulsarService: Pulsar4Service, private fb: FormBuilder, private router: Router) {
  }

  myForm: FormGroup;

  ngOnInit() {

    this.templateID = this.currentTemplateService.id;

    if (this.templateID != 'new') this.GrabTemplate(this.templateID);
    else this.isLoading = false;

    setTimeout(() => {
  	this.appStateService.hideSidebar();
      }, 
    0);

	this.myForm = this.fb.group({
	  Name: ''
	});

  }

  onSubmit(form: FormGroup) {

    this.isUpdating = true;

    let formData = {
	  Name: form.value.Name
	};

    if (this.templateID != 'new') {
	  this.pulsarService.putTemplate(this.templateID, formData).subscribe(Template => {
		  this.router.navigate(['/template']);
	  });    	  
	}
    else {
	  this.pulsarService.postTemplate(formData).subscribe(Template => {
		  this.router.navigate(['/template']);
	  });    	  
	}

  }

  public GrabTemplate(templateID) {

	  this.isLoading = true;
	  this.pulsarService.getTemplate(templateID).subscribe(Template => {

	  	  let formData = {
		  	Name: Template.Template.Name
		  };
		  
		  this.myForm.patchValue(formData);

		  this.isLoading = false;
	  });    	  

  }

}