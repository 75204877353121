import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '../environments/environment';

export const authConfig: AuthConfig = {

    // Login-Url
    loginUrl: "https://auth.pivotel.com.au/pulsar4/authorize", //Id-Provider?

    // URL of the SPA to redirect the user to after login
    redirectUri: environment.redirectUri,

    // The SPA's id. Register SPA with this id at the auth-server
    clientId: environment.clientId,

    // set the scope for the permissions the client should request
    scope: "profile email pulsar4",

    // To also enable single-sign-out set the url for your auth-server's logout-endpoint here
    logoutUrl: "https://auth.pivotel.com.au/pulsar4/logout",
    
    issuer: "https://auth.pivotel.com.au",
        
	showDebugInformation: false

}
