import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { LogoutComponent } from './logout/logout.component';

import { AlertsComponent } from './alerts/alerts.component';
import { AlertComponent } from './alert/alert.component';
import { CurrentAlertResolver } from './alert/current-alert-resolver.service';

import { BarsComponent } from './bars/bars.component';
import { BarComponent } from './bar/bar.component';
import { CurrentBarResolver } from './bar/current-bar-resolver.service';

import { FirewallsComponent } from './firewalls/firewalls.component';
import { FirewallComponent } from './firewall/firewall.component';
import { CurrentFirewallResolver } from './firewall/current-firewall-resolver.service';

import { ServicesComponent } from './services/services.component';
import { ServiceComponent } from './service/service.component';
import { CurrentServiceResolver } from './service/current-service-resolver.service';

import { TagsComponent } from './tags/tags.component';
import { TagComponent } from './tag/tag.component';
import { CurrentTagResolver } from './tag/current-tag-resolver.service';

import { TemplatesComponent } from './templates/templates.component';
import { TemplateComponent } from './template/template.component';
import { CurrentTemplateResolver } from './template/current-template-resolver.service';

import { AuthGuard } from './shared';

const routes: Routes = [

  { path: 'alert-profile', component: AlertsComponent, canActivate: [AuthGuard], data: {permission:{require:'pulsar4'}} },	
  { path: 'alert-profile/:id', component: AlertComponent, canActivate: [AuthGuard], data: {permission:{require:'pulsar4'}}, resolve: { id: CurrentAlertResolver } },	

  { path: 'bar-profile', component: BarsComponent, canActivate: [AuthGuard], data: {permission:{require:'pulsar4'}} },	
  { path: 'bar-profile/:id', component: BarComponent, canActivate: [AuthGuard], data: {permission:{require:'pulsar4'}}, resolve: { id: CurrentBarResolver } },	

  { path: 'firewall-profile', component: FirewallsComponent, canActivate: [AuthGuard], data: {permission:{require:'pulsar4'}} },	
  { path: 'firewall-profile/:id', component: FirewallComponent, resolve: { id: CurrentFirewallResolver } },	

  { path: 'service', component: ServicesComponent, canActivate: [AuthGuard], data: {permission:{require:'pulsar4'}} },	
  { path: 'service/:id', component: ServiceComponent, canActivate: [AuthGuard], data: {permission:{require:'pulsar4'}}, resolve: { id: CurrentServiceResolver } },	

  { path: 'tag', component: TagsComponent, canActivate: [AuthGuard], data: {permission:{require:'pulsar4'}} },	
  { path: 'tag/:id', component: TagComponent, canActivate: [AuthGuard], data: {permission:{require:'pulsar4'}}, resolve: { id: CurrentTagResolver } },	

  { path: 'template', component: TemplatesComponent, canActivate: [AuthGuard], data: {permission:{require:'pulsar4'}} },	
  { path: 'template/:id', component: TemplateComponent, canActivate: [AuthGuard], data: {permission:{require:'pulsar4'}}, resolve: { id: CurrentTemplateResolver } },	

  { path: 'logout', component: LogoutComponent },
  { path: 'home', component: DashboardComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', redirectTo: 'home' }	
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
