<div style="position: relative" stTable #table="stTable" [ngClass]="{'list-preview-margin': showCard}">

	<div class="d-flex justify-content-between my-3">  

		<form class="flex-grow-1">
			<div class="form-row">
			    <div class="col">
			      <input type="text" class="form-control" placeholder="Filter Alert Profiles" stSearch="Profile.name">
			    </div>
			</div>
		</form>

		<div class="text-right ml-2">
			<a [routerLink]="['/alert-profile','new']" class="btn btn-primary"><fa-icon [icon]="faPlus" class="mr-1"></fa-icon> Create Alert Profile</a>
		</div>

	</div>

	<table class="table">
	  <thead>
	    <tr>
	      <th scope="col">Name</th>
	      <th scope="col">Status</th>
	    </tr>
	  </thead>
	  <tbody>
	    <tr *ngFor="let Profile of table.items; let i = index" (click)="RowSelected(i, Profile, ProfileRow);" [class.table-active]="i === selectedRow" #ProfileRow>
	      <td>{{Profile.name}}</td>
	      <td>
		      <strong class="text-success" *ngIf="Profile.status == 'Enabled'">Enabled</strong>
		      <strong class="text-danger" *ngIf="Profile.status != 'Enabled'">Disabled</strong>
		  </td>
	    </tr>
	  </tbody>
	</table>

	<div stPagination #pager="stPagination" class="d-flex justify-content-between">
		<div class="d-flex">
			<div *ngIf="table.busy; else pages" class="loading-indicator text-center" style="position: relative;"><div class="loading-spinner loading-spinner-small"></div></div>
			<ng-template #pages>
				<p>Showing items <em>{{pager.length > 0 ? pager.lowerBoundIndex + 1 : 0}}</em> - <em>{{pager.higherBoundIndex +
          1}}</em> of <em>{{pager.length}} </em> items
				</p>
			</ng-template>
		</div>

		<ul class="pagination">
			<li class="page-item" [ngClass]="{'disabled': !pager.isPreviousPageEnabled()}"><button class="page-link" (click)="pager.selectPreviousPage()" >Previous</button></li>
			<li class="page-item" [ngClass]="{'disabled': !pager.isNextPageEnabled()}"><button class="page-link" (click)="pager.selectNextPage()" >Next</button></li>
		</ul>
	</div>

</div>

<div class="card shadow list-preview" @fade *ngIf="showCard">
  <div class="card-body">
	  
	<div class="d-flex justify-content-between">  
    	<h5 class="card-title mb-0">{{ActiveProfile.name}}</h5>
    	<div class="text-right">

			<a [routerLink]="['/alert-profile',ActiveProfile.id]" class="btn btn-sm btn-secondary mr-2"><fa-icon [icon]="faEdit" class="mr-1"></fa-icon> Modify</a>

			<!--<a [routerLink]="" class="btn btn-sm btn-secondary mr-4"><fa-icon [icon]="faCopy" class="mr-1"></fa-icon> Copy</a>-->
			<button (click)="deleteProfile(ActiveProfile)" class="btn btn-sm btn-danger mr-2"><fa-icon [icon]="faTrash" class="mr-1"></fa-icon> Delete</button>

			<!--<a routerLink="/service" class="btn btn-sm btn-primary mr-2"><fa-icon [icon]="faSatelliteDish" class="mr-1"></fa-icon> Find Services</a>-->

    	</div>
	</div>

	<ul ngbNav #nav="ngbNav" class="nav-pills mt-2">
	  <li ngbNavItem>
	    <a ngbNavLink>Summary</a>
	    <ng-template ngbNavContent> 
	    
			<div class="mt-3">
				<dl class="row mb-0">
				  <dt class="col-sm-3 text-right">Last Updated</dt>
				  <dd class="col-sm-9">{{ActiveProfile.connectionDateTime | date: 'long'}}</dd>

				  <dt class="col-sm-3 text-right">Status</dt>
				  <dd class="col-sm-9" *ngIf="ActiveProfile.status == 'Enabled'"><strong class="text-success">Enabled</strong></dd>
				  <dd class="col-sm-9" *ngIf="ActiveProfile.status != 'Enabled'"><strong class="text-danger">Disabled</strong></dd>

				  <!--<dt class="col-sm-3 text-right">Description</dt>
				  <dd class="col-sm-9">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc euismod quam est, vel facilisis enim semper in. Sed sit amet nunc at tellus dapibus accumsan id et nunc.</dd>-->
				</dl>
			</div>

	    </ng-template>
	  </li>
	  <li ngbNavItem>
	    <a ngbNavLink>Rules</a>
	    <ng-template ngbNavContent>
	    	    
	    	<br>
	    	
			<table class="table">
			  <thead>
			    <tr>
			      <th scope="col">Type</th>
			      <th scope="col">Metric</th>
			      <th scope="col">Exceeds</th>
			    </tr>
			  </thead>
			  <tbody>
			    <tr *ngFor="let actions of ActiveProfile.actions">
			      <td>Data</td>
			      <td>{{actions.monitor}}</td>
			      <td>{{actions.volume | bytes}} {{actions.percentage}}</td>
			    </tr>
			  </tbody>
			</table>
	    
	    </ng-template>
	  </li>
	  <li ngbNavItem>
	    <a ngbNavLink>Notifications</a>
	    <ng-template ngbNavContent>

	    	<br>
	    
			<table class="table">
			  <thead>
			    <tr>
			      <th scope="col">Type</th>
			      <th scope="col">Destination</th>
			    </tr>
			  </thead>
			  <tbody>
			    <tr *ngFor="let Notification of ActiveProfile.Notifications">
			      <td *ngIf="Notification.Type == 'Email'">Email</td>
			      <td *ngIf="Notification.Type == 'TextMessage'">Text Message</td>
			      <td>{{Notification.Destination}}</td>
			    </tr>
			  </tbody>
			</table>
	    
	    </ng-template>
	  </li>

	</ul>
	
	<div [ngbNavOutlet]="nav"></div>


  </div>
</div>
