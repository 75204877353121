import { Component, OnInit } from '@angular/core';

import { faHome, faSatelliteDish, faUser, faSearch, faChessRook, faLayerGroup, faBells, faWifi, faUsers, faLock, faTags, faGlobe, faMailbox, faFileExport, faExternalLink} from '@fortawesome/pro-regular-svg-icons';

import { AppStateService } from './app-state.service';
import { Pulsar4Service } from './pulsar-4.service';

import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { authConfig } from './auth.config';

import { AuthFunction } from './shared';
import { Router } from '@angular/router';

import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	
  title = 'pulsar4';
  
  faUser = faUser;
  faHome = faHome;
  faSearch = faSearch;
  faSatelliteDish = faSatelliteDish;
  faChessRook = faChessRook;
  faLayerGroup = faLayerGroup;
  faBells = faBells;
  faWifi = faWifi;
  faUsers = faUsers;
  faLock = faLock;
  faTags = faTags;
  faGlobe = faGlobe;
  faMailbox = faMailbox;
  faFileExport = faFileExport;
  faExternalLink = faExternalLink;
  
  selectedAccountNumber = this.appStateService.getCurrentAccountNumber();
  
  Accounts = [];
    
  constructor(private oauthService: OAuthService, private auth: AuthFunction, public appStateService: AppStateService, private pulsarService: Pulsar4Service, private router: Router) {
	  
	  this.oauthService.configure(authConfig);
	        
	  // Use setStorage to use sessionStorage or another implementation of the TS-type Storage
	  // instead of localStorage
	  this.oauthService.setStorage(sessionStorage);
		  
	  this.oauthService.tryLogin();
		
	  this.oauthService.events.subscribe(e => console.log('oauth/oidc event', e)); // just for logging
	
	  this.oauthService.events
	    .pipe(filter(e => e.type === 'token_expires'))
	    .subscribe(e => {
	    	this.router.navigate(['/logout']);
	  });
	  
	  this.oauthService.events
	    .pipe(filter(e => e.type === 'token_received'))
	    .subscribe(e => {
	    	this.GrabUser();
	  });

  }

  ngOnInit() {

	if (this.auth.isLoggedin) {
	  this.GrabUser();
	}
    
  }

  public get isSidebarVisible(): boolean {
    return this.appStateService.isSidebarVisible;
  }
 
  public get isLoggedin() {
  	return this.auth.isLoggedin;
  }

  public logoff() {
    this.oauthService.logOut();
  }
  
  public GrabUser() {

	  //this.isLoading = true;
	  this.pulsarService.getUser().subscribe(User => {
		  
		this.appStateService.setUser(User.User);
		this.Accounts = User.Accounts;
		
		if (!this.selectedAccountNumber) {
			this.selectedAccountNumber = User.Accounts[0].accountNum;
			this.appStateService.setCurrentAccountNumber(User.Accounts[0].accountNum);
			this.appStateService.setAccount(User.Accounts[0]);
		} else {
			let Account = User.Accounts.find(o => o.accountNum === this.selectedAccountNumber.toString());
			if (Account) {
				this.appStateService.setAccount(Account);				
			} else {
				// Reset to Default
				this.selectedAccountNumber = User.Accounts[0].accountNum;
				this.appStateService.setCurrentAccountNumber(User.Accounts[0].accountNum);
				this.appStateService.setAccount(User.Accounts[0]);				
			}
		}
	  	//this.isLoading = false;
	  	
	  });    	  

  }
  
  public onChangeAccount() {
    let Account = this.Accounts.find(o => o.accountNum === this.selectedAccountNumber.toString());
	if (Account) {
	  this.appStateService.setCurrentAccountNumber(this.selectedAccountNumber);
	  this.appStateService.setAccount(Account);				
	} else {
	  alert('nope');
	}
  }
  
}