
<div *ngIf="!isLoading; else loading">

	<div class="text-center">
		<div class="rounded-circle d-inline-block text-white p-3 m-2" id="pulsarIcon">
			<span class="d-inline-block pt-2" style="width: 75px; height: 75px"><fa-icon [icon]="faSatellite" size="4x" [fixedWidth]="true"></fa-icon></span>	
		</div>
	
		<h1 class="mb-4">{{Account['fullName']}}</h1>
	</div>
	
	<div class="row">
		
		<div class="col">
			
			<div class="card">
				<div class="card-body">
	
					<a href="https://www.pivotel.com.au/current-outages/" class="btn btn-sm btn-primary float-right" target="_blank"><fa-icon [icon]="faEye" class="mr-1"></fa-icon> Pivotel Outages</a>				
					<h5 class="card-title mb-4">Network Status</h5>
	
					<div class="row">
						<div class="col text-center">
							<span class="text-success d-block mb-1"><fa-icon [icon]="faCheckCircle" size="3x" [fixedWidth]="true"></fa-icon></span>
							<span style="font-size: 1.6em">Pivotel</span>
						</div>
						<div class="col text-center">
							<span class="text-success d-block mb-1"><fa-icon [icon]="faCheckCircle" size="3x" [fixedWidth]="true"></fa-icon></span>
							<span style="font-size: 1.6em">Inmarsat</span>
						</div>
						<div class="col text-center">
							<span class="text-success d-block mb-1"><fa-icon [icon]="faCheckCircle" size="3x" [fixedWidth]="true"></fa-icon></span>
							<span style="font-size: 1.6em">Iridium</span>
						</div>
						<div class="col text-center">
							<span class="text-success d-block mb-1"><fa-icon [icon]="faCheckCircle" size="3x" [fixedWidth]="true"></fa-icon></span>
							<span style="font-size: 1.6em">Thuraya</span>
						</div>
					</div>
	
				</div>
			</div>
			
		</div>
		<div class="col">
			
			<div class="card">
				<div class="card-body">
					
					<a routerLink="/service" class="btn btn-sm btn-primary float-right"><fa-icon [icon]="faSatelliteDish" class="mr-1"></fa-icon> View All</a>
					<h5 class="card-title mb-4">Devices</h5>
					
					<div class="row">
						<div class="col text-center">
							<span class="text-success d-block mb-1"><fa-icon [icon]="faWifi" size="3x" [fixedWidth]="true"></fa-icon></span>
							<span style="font-size: 1.6em">{{Dashboard.Services.networkStatusCount.online}}</span>
						</div>
						<div class="col text-center">
							<span class="text-danger d-block mb-1"><fa-icon [icon]="faWifiSlash" size="3x" [fixedWidth]="true"></fa-icon></span>
							<span style="font-size: 1.6em">{{Dashboard.Services.networkStatusCount.offline}}</span>
						</div>
					</div>
	
				</div>
			</div>		
			
		</div>
		
	</div>
	
	<!--<hr>
	
	<div class="row">
		
		<div class="col">
			
			<div class="card">
				<div class="card-body">
	
				    	<h5 class="card-title">Notifications</h5>
	
						<table class="table mb-0">
						  <tbody>
						    <tr>
						      <td><fa-icon [icon]="faEnvelopeOpenText" [fixedWidth]="true"></fa-icon></td>
						      <td><a href="#">Pulsar Alert Notification for Hughes 9502</a></td>
						      <td>15 May 2017 at 07:00:00 -07:00</td>
						    </tr>
						    <tr>
						      <td><fa-icon [icon]="faEnvelope" [fixedWidth]="true"></fa-icon></td>
						      <td><a href="#">Pulsar Alert Notification for Hughes 9502</a></td>
						      <td>15 May 2017 at 07:00:00 -07:00</td>
						    </tr>
						    <tr>
						      <td><fa-icon [icon]="faEnvelope" [fixedWidth]="true"></fa-icon></td>
						      <td><a href="#">Pulsar Alert Notification for Hughes 9502</a></td>
						      <td>15 May 2017 at 07:00:00 -07:00</td>
						    </tr>
						  </tbody>
						</table>
	
				</div>
			</div>
			
		</div>
	
	</div>-->

</div>

<ng-template #loading>
  <div class="loading-spinner"></div>
</ng-template>