import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

import { faEdit, faChevronLeft, faLock, faLockOpen, faFire, faChartBar, faChartLine, faChevronCircleUp, faStream, faBells, faWifi, faWifiSlash, faChessRook, faCalendarAlt, faClipboard, faEye, faPlusCircle, faCalendarTimes, faGlobe, faChevronCircleDown, faCheck, faTimes, faSync, faSatelliteDish } from '@fortawesome/pro-regular-svg-icons';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { CurrentServiceService } from './current-service.service';
import { Pulsar4Service } from '../pulsar-4.service';

import { AppStateService } from '../app-state.service';

import { trigger, state, style, animate, transition } from '@angular/animations';

import { Observable, of } from 'rxjs';

import * as moment from "moment";

import {
  ChartType,
} from 'angular-google-charts';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss'],
  animations: [
    trigger('fadeField', [      
      transition('void => *', [
        style({opacity: 0}),
        animate(200, style({opacity: 1}))
      ])
    ])
  ]
})
export class ServiceComponent implements OnInit {

  selectedId: string;

  constructor(private modalService: NgbModal, private currentServiceService: CurrentServiceService, private pulsarService: Pulsar4Service, private appStateService: AppStateService, private route: ActivatedRoute) {}

  faEdit = faEdit;
  faChevronLeft = faChevronLeft;
  faFire = faFire;
  faChevronCircleUp = faChevronCircleUp;
  faChevronCircleDown = faChevronCircleDown;
  faChartBar = faChartBar;
  faChartLine = faChartLine;  
  faStream = faStream;
  faBells = faBells;
  faWifi = faWifi;
  faWifiSlash = faWifiSlash;
  faChessRook = faChessRook;
  faCalendarAlt = faCalendarAlt; 
  faLock = faLock;
  faLockOpen = faLockOpen;
  faClipboard = faClipboard;
  faEye = faEye;
  faPlusCircle = faPlusCircle;
  faCalendarTimes = faCalendarTimes; 
  faGlobe = faGlobe;
  faCheck = faCheck;
  faTimes = faTimes;
  faSync = faSync;
  faSatelliteDish = faSatelliteDish;

  moment = moment;

  public isLoading = true;  
  
  ServiceDetail: any = {'ipAddressList':[]};
  DataUsage: any = {'AlertingProfiles':[],'BarringProfiles':[]};
  Attributes: any ={};
  DataGroup: any = [];
  
  public serviceID;

  IsCollapsed = this.appStateService.getIsCollapsed();
  IsEditing = [];
  public isUpdating = false;
  public isUpdatingBar = false;
  
  ngOnInit(): void {

	  this.route.params.subscribe(params => {
	    this.doOnInit();
	  });

  }

  private doOnInit() {
	  
	  setTimeout(() => {
	  this.appStateService.showSidebar();
	    }, 
	  0);

	  this.serviceID = this.currentServiceService.id;
	  this.GrabService(this.serviceID);

	  /*$(".progress-round").each(function() {
	
	    let value: number = Number($(this).attr('data-value'));
	    var left = $(this).find('.progress-left .progress-bar');
	    var right = $(this).find('.progress-right .progress-bar');
	
	    if (value > 0) {
	      if (value <= 50) {
	        right.css('transform', 'rotate(' + percentageToDegrees(value) + 'deg)')
	      } else {
	        right.css('transform', 'rotate(180deg)')
	        left.css('transform', 'rotate(' + percentageToDegrees(value - 50) + 'deg)')
	      }
	    }
	
	  })*/

  }
  
  public GrabService(serviceID) {

	  this.isLoading = true;
	  this.pulsarService.getService(serviceID).subscribe(Service => {
	  	if (Service.Service) this.ServiceDetail = Service.Service;
	  	if (Service.DataUsage) this.DataUsage = Service.DataUsage;
	  	if (Service.Attributes) this.Attributes = Service.Attributes;
	  	if (Service.DataGroup) this.DataGroup = Service.DataGroup;
	  	this.isLoading = false;
	  });    	  

  }

  ToggleCollapse(element) {
    this.IsCollapsed[element] = !this.IsCollapsed[element];
    this.appStateService.setIsCollapsed(this.IsCollapsed);
  }
  
  toggleBar() {

    this.isUpdatingBar = true;
    
    if (this.ServiceDetail.dataStatus == 'Unbarred') {
		this.pulsarService.putServiceBar(this.serviceID).subscribe(Service => {
		  this.ServiceDetail.dataStatus = Service.Service.dataStatus;
		  this.isUpdatingBar = false;
		});    	  	    
    }

    if (this.ServiceDetail.dataStatus == 'Barred') {
		this.pulsarService.putServiceUnbar(this.serviceID).subscribe(Service => {
		  this.ServiceDetail.dataStatus = Service.Service.dataStatus;
		  this.isUpdatingBar = false;
		});    	  	    
    }
    
  }

  summaryUsage(content) {
    this.modalService.open(content, { size: 'lg' });
  }
  
  editField(element) {
    this.IsEditing[element] = !this.IsEditing[element];
  }

  hideField(element) {
    this.IsEditing[element] = !this.IsEditing[element];
  }

  updateField(element) {
	  
	this.isUpdating = true;
	
	let formData = {};	
	
	switch(element) {
				
	  case 'displayName':

	    formData['DisplayName'] = this.ServiceDetail.displayName;

		this.pulsarService.putServiceDisplayName(this.serviceID, formData).subscribe(Tag => {
		  this.isUpdating = false;
		  this.IsEditing[element] = !this.IsEditing[element];
		});    	  

	    break;
	  case 'Description':

	    formData['Description'] = this.Attributes.Description;

		this.pulsarService.putServiceDescription(this.serviceID, formData).subscribe(Tag => {
		  this.isUpdating = false;
		  this.IsEditing[element] = !this.IsEditing[element];
		});    	  

	    break;
	  case 'Tags': 
	  
	    let tagArray = [];
	  
	    this.Attributes.Tags.forEach(Tag => {
		    tagArray.push(Tag.value)
	    }); 
	  
	    formData['Tags'] = tagArray;

		this.pulsarService.putServiceTags(this.serviceID, formData).subscribe(Tag => {
		  this.isUpdating = false;
		  this.IsEditing[element] = !this.IsEditing[element];
		});    	  

	    break;
	  case 'AlertProfile': 
	  
	    let alertProfileArray = [];
	  
	    this.DataUsage.AlertingProfiles.forEach(Tag => {
		    alertProfileArray.push(Tag.value)
	    }); 
	  
	    formData['Profiles'] = alertProfileArray;

		this.pulsarService.putServiceAlertingProfiles(this.serviceID, formData).subscribe(Tag => {
		  this.isUpdating = false;
		  this.IsEditing[element] = !this.IsEditing[element];
		});    	  

	    break;
	  case 'BarProfile': 
	  
	    let barProfileArray = [];
	  
	    this.DataUsage.BarringProfiles.forEach(Tag => {
		    barProfileArray.push(Tag.value)
	    }); 
	  
	    formData['Profiles'] = barProfileArray;

		this.pulsarService.putServiceBarringProfiles(this.serviceID, formData).subscribe(Tag => {
		  this.isUpdating = false;
		  this.IsEditing[element] = !this.IsEditing[element];
		});    	  

	    break;
	  case 'FirewallProfile': 
	  
	    let firewallProfileArray = [];
	  
	    this.Attributes.FirewallProfile.forEach(Tag => {
		    firewallProfileArray.push(Tag.value)
	    }); 
	  
	    formData['Profiles'] = firewallProfileArray;

		this.pulsarService.putServiceFirewallProfiles(this.serviceID, formData).subscribe(Tag => {
		  this.isUpdating = false;
		  this.IsEditing[element] = !this.IsEditing[element];
		});    	  

	    break;
	  default:
	  	this.isUpdating = false;
	  	this.IsEditing[element] = !this.IsEditing[element];
	}

  }

  myType = ChartType.ColumnChart;

  myData = [
    ['2020-08-01', 8.1, 2.1],
    ['2020-08-02', 8.5, 6.5],
    ['2020-08-03', 4.2, 2.2],
    ['2020-08-04', 3.4, 1.4],
    ['2020-08-05', 5.9, 1.9],
    ['2020-08-01', 8.1, 2.1],
    ['2020-08-02', 8.5, 6.5],
    ['2020-08-03', 4.2, 2.2],
    ['2020-08-04', 3.4, 1.4],
    ['2020-08-05', 5.9, 1.9],
    ['2020-08-01', 8.1, 2.1],
    ['2020-08-02', 8.5, 6.5],
    ['2020-08-03', 4.2, 2.2],
    ['2020-08-04', 3.4, 1.4],
    ['2020-08-05', 5.9, 1.9]
  ];

  public ScrollTo(element) {
    element.scrollIntoView({behavior:"smooth"});
  }

  public autocompleteTags = (text: string): Observable<any[]> => {
    return this.pulsarService.getTagsAutocomplete(this.ServiceDetail.accountNum, '');
  }

  public autocompleteAlertingProfiles = (text: string): Observable<any[]> => {
    return this.pulsarService.getAlertingProfilesAutocomplete(this.ServiceDetail.accountNum, '');
  }

  public autocompleteBarringProfiles = (text: string): Observable<any[]> => {
    return this.pulsarService.getBarringProfilesAutocomplete(this.ServiceDetail.accountNum, '');
  }

  public autocompleteFirewallProfiles = (text: string): Observable<any[]> => {
    return this.pulsarService.getFirewallProfilesAutocomplete(this.ServiceDetail.accountNum, '');
  }

}

function percentageToDegrees(percentage) {
	return percentage / 100 * 360
}
